export const times = [
  {
    date: '2025-01-07',
    label: '07.Jan.Tue',
    hours: ['10AM', '11AM', '12AM', '1PM', '2PM', '3PM', '4PM', '5PM'],
    startTimes: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30'],
    endTimes: ['10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', ],
  },
  {
    date: '2025-01-08',
    label: '08.Jan.Wed',
    hours: ['09AM', '10AM', '11AM', '12AM', '1PM', '2PM', '3PM', '4PM', '5PM'],
    startTimes: ['09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30'],
    endTimes: ['09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', ],
  },
  {
    date: '2025-01-09',
    label: '09.Jan.Thu',
    hours: ['09AM', '10AM', '11AM', '12AM', '1PM', '2PM', '3PM', '4PM', '5PM'],
    startTimes: ['09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30'],
    endTimes: ['09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', ],
  },
  {
    date: '2025-01-10',
    label: '10.Jan.Fri',
    hours: ['09AM', '10AM', '11AM', '12AM', '1PM', '2PM', '3PM'],
    startTimes: ['09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30'],
    endTimes: ['09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00'],
  }
];

export const rooms = [
  { room: 'A1', name: 'A1', capacity: 8, secret: false, shape: 'rect', coords: [296,55,219,120], map: 'room-c' },
  { room: 'A2', name: 'A2', capacity: 8, secret: false, shape: 'rect', coords: [375,57,297,119], map: 'room-c' },
  { room: 'A3', name: 'A3', capacity: 8, secret: false, shape: 'rect', coords: [297,216,218,279], map: 'room-c' },
  { room: 'A4', name: 'A4', capacity: 8, secret: false, shape: 'rect', coords: [375,216,297,279], map: 'room-c' },
  { room: 'A5', name: 'A5', capacity: 9, secret: false, shape: 'rect', coords: [31,120,124,57], map: 'room-c' },
  { room: 'A6', name: 'A6', capacity: 9, secret: false, shape: 'rect', coords: [219,57,122,119], map: 'room-c' },
  { room: 'A7', name: 'A7', capacity: 9, secret: false, shape: 'rect', coords: [31,216,122,279], map: 'room-c' },
  { room: 'A8', name: 'A8', capacity: 9, secret: false, shape: 'rect', coords: [218,216,125,281], map: 'room-c' },
  { room: 'A9', name: 'A9', capacity: 20, secret: false, shape: 'rect', coords: [421,55,487,281], map: 'room-c' },
];