import { useState } from 'react';
import iconApply from '@assets/images/icon_s5.svg';
import iconSerial from '@assets/images/icon_s6.svg';
import iconTicket from '@assets/images/icon_s7.svg';
import iconPrint from '@assets/images/icon_s8.svg';
import iconReceipt from '@assets/images/icon_s9.svg';

export default function RoomInfomation() {
  const [lang, setLang] = useState('kr');

  return (
    <>
      <div className="container_top ticket_top">
        <div className="top-info top-info_cn">
          <h2 className="top-title">Information</h2>
        </div>
      </div>
      <div className="container_body-tit">
        <h3></h3>
        <div className="container_lang">
        <button className={`btn-en ${lang === 'en' ? 'on':''}`} onClick={() => setLang('en')}><span className="spsans">EN</span></button>
        <button className={`btn-kr ${lang === 'kr' ? 'on':''}`} onClick={() => setLang('kr')}><span className="spsans">KR</span></button>
        </div>
      </div>
      {
        lang === 'en' ? (
          <section className="information_en">
            <div className="information_announce">
              <div className="information_inner">
                <h4>Notice</h4>
              </div>
              <div className="information_inner">
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> Welcome to the Samsung CES Business lounge reservation page.</p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> The Samsung Business lounge is located on North Hall, Level 2.</p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> Reservations are available in 30-minute increments, with a maximum of 1 hour per day.<br />*1day per max 2slots=1hour, 1slot: 30min</p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> Business lounge usage will be confirmed after final approval by the administrator, and notification emails will be sent between late December and early January.<br />* Upon submission, your reservation will remain in “Pending Approval” status.</p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> If any changes are necessary after final confirmation, please notify the administrator immediately.<br/>* Jeffrey Fairbanks: <a href="mailto:j.Fairbanks@cheil.com" className="ft_blue ssone">j.Fairbanks@cheil.com</a></p>
              </div>
            </div>
            <div className="information_process">
              <div className="information_inner">
                <h4>Reservation process</h4>
              </div>
              <div className="information_inner">
                <div className="information_process-box information_process-box4">
                  <div className="process_item process_item4">
                    <div>
                      <span className="process_item-no">01</span>
                      <img src={iconApply} alt="reservation" className="mb30" />
                      <p>Submit Reservation</p>
                    </div>
                  </div>
                  <div className="process_item process_item4">
                    <div>
                      <span className="process_item-no">02</span>
                      <img src={iconReceipt} alt="reservation" className="mb30" />
                      <p>Administrator Review</p>
                    </div>
                  </div>
                  <div className="process_item process_item4">
                    <div>
                      <span className="process_item-no">03</span>
                      <img src={iconTicket} alt="reservation" className="mb30" />
                      <p>Approval / Denial</p>
                    </div>
                  </div>
                  <div className="process_item process_item4 no_background_item4">
                    <div>
                      <span className="process_item-no">04</span>
                      <img src={iconPrint} alt="reservation" className="mb30" />
                      <p>Notification Email</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="information_announce">
              <div className="information_inner">
                <h4>Opening hour</h4>
              </div>
              <div className="information_inner">
                  <p className='mb15 sp_p'><span className='sp_sp'>-</span> Tue, Jan 7  : 10:00 AM - 06:00 PM</p>
                  <p className='mb15 sp_p'><span className='sp_sp'>-</span> Wed, Jan 8 : 09:00 AM - 06:00 PM</p>
                  <p className='mb15 sp_p'><span className='sp_sp'>-</span> Thu, Jan 9  : 09:00 AM - 06:00 PM</p>
                  <p className='mb15 sp_p'><span className='sp_sp'>-</span> Fri, Jan 10  : 09:00 AM - 04:00 PM</p>
              </div>
            </div>
            <div className="information_announce">
              <div className="information_inner">
                <h4>Courtesy</h4>
              </div>
              <div className="information_inner">
                <p className='mb15 sp_p'><span className='sp_sp'>-</span>Please adhere to your reserved time to accommodate the next users.</p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span>Cancel your reservation immediately if you will not be using the business lounge.</p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span>Ensure the number of attendees does not exceed the capacity limit, as on-site changes will not be allowed.</p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span>Handle all facilities with care to avoid damage.</p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span>Keep the business lounge clean after use.</p>
              </div>
            </div>
            {/*
            <div className="information_announce">
              <div className="information_inner">
                <h4>For more info</h4>
              </div>
              <div className="information_inner">
                <p className='mb10 sp_p'><span className='sp_sp'>-</span> Jeffrey Fairbanks - <a href="mailto:j.fairbanks@cheil.com" class="ssone">j.fairbanks@cheil.com</a></p>
              </div>
            </div>
            */}
          </section>
        ) : (
          <section className="information_en">
            <div className="information_announce">
              <div className="information_inner">
                <h4>Notice</h4>
              </div>
              <div className="information_inner">
                  <p className='mb15 sp_p'><span className='sp_sp'>-</span> Samsung CES Buesiness lounge 예약 페이지 오신 것을 환영합니다.</p>
                  <p className='mb15 sp_p'><span className='sp_sp'>-</span> 삼성 비즈니스라운지는 North hall Level 2에 위치하고 있습니다.</p>
                  <p className='mb15 sp_p'><span className='sp_sp'>-</span> 예약은 30분 단위로 하루 1시간까지 예약 가능합니다.<br/>*1day per max 2slots=1hour, 1slot: 30min</p>
                  <p className='mb15 sp_p'><span className='sp_sp'>-</span> 회의실은 담당자 최종 승인 이후 사용 여부 확정되며 12월말-1월초 안내 메일 발송될 예정이오니 참고 부탁드립니다.<br/>*신청 즉시 예약 결과 대기 상태</p>
                  <p className='mb15 sp_p'><span className='sp_sp'>-</span> 부득이한 사정으로 최종 확정 이후 변동 사항있으실 경우 반드시 담당자 측에 즉시 연락 부탁드립니다.<br/>Jeffrey Fairbanks : <a href="mailto:j.Fairbanks@cheil.com" className="ft_blue ssone">j.Fairbanks@cheil.com</a></p>
              </div>
            </div>
            <div className="information_process">
              <div className="information_inner">
                <h4>Reservation process</h4>
              </div>
              <div className="information_inner">
                <div className="information_process-box information_process-box4">
                  <div className="process_item process_item4">
                    <div>
                      <span className="process_item-no">01</span>
                      <img src={iconApply} alt="reservation" className="mb30" />
                      <p>비즈니스 라운지 예약 신청</p>
                    </div>
                  </div>
                  <div className="process_item process_item4">
                    <div>
                      <span className="process_item-no">02</span>
                      <img src={iconReceipt} alt="reservation" className="mb30" />
                      <p>관리자 검토</p>
                    </div>
                  </div>
                  <div className="process_item process_item4">
                    <div>
                      <span className="process_item-no">03</span>
                      <img src={iconTicket} alt="reservation" className="mb30" />
                      <p>관리자 승인 / 거절</p>
                    </div>
                  </div>
                  <div className="process_item process_item4 no_background_item4">
                    <div>
                      <span className="process_item-no">04</span>
                      <img src={iconPrint} alt="reservation" className="mb30" />
                      <p>이메일로 결과 안내</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="information_announce">
              <div className="information_inner">
                <h4>Opening hour</h4>
              </div>
              <div className="information_inner">
                  <p className='mb15 sp_p'><span className='sp_sp'>-</span> 1월 7일 화요일, 오전 10시 ~ 오후 6시</p>
                  <p className='mb15 sp_p'><span className='sp_sp'>-</span> 1월 8일 수요일, 오전 9시 ~ 오후 6시</p>
                  <p className='mb15 sp_p'><span className='sp_sp'>-</span> 1월 9일 목요일, 오전 9시 ~ 오후 6시</p>
                  <p className='mb15 sp_p'><span className='sp_sp'>-</span> 1월 10일 금요일, 오전 9시 ~ 오후 4시</p>
                  {/* 
                  <div className="infor_day mb10"><span>- 1월 5일 목요일</span> <span className="inner_d2">[ 오전 10시 ~ 오후 6시  ]</span></div>
                  <div className="infor_day mb10"><span>- 1월 6일 금요일</span> <span className="inner_d2">[ 오전 9시 ~ 오후 6시  ]</span></div>
                  <div className="infor_day mb10"><span>- 1월 7일 토요일</span> <span className="inner_d2">[ 전시: 오전 9시 ~ 오후 5시  ]</span></div>
                  <div className="infor_day"><span>- 1월 8일 일요일</span> <span className="inner_d2">[ 오전 9시 ~ 오후 4시 ]</span></div>
                  */}
              </div>
            </div>
            <div className="information_announce">
              <div className="information_inner">
                <h4>Courtesy</h4>
              </div>
              <div className="information_inner">
                  <p className='mb15 sp_p'><span className='sp_sp'>-</span> 다음 예약자를 위해 이용 시간을 준수해주십시오.</p>
                  <p className='mb15 sp_p'><span className='sp_sp'>-</span> 회의실 미사용 시 즉시 취소 부탁드립니다.</p>
                  <p className='mb15 sp_p'><span className='sp_sp'>-</span> 현장 추가 불가로 반드시 수용 인원 이내 입장해주시기 바랍니다.</p>
                  <p className='mb15 sp_p'><span className='sp_sp'>-</span> 시설 이용 시, 파손 유의 부탁드립니다.</p>
                  <p className='mb15 sp_p'><span className='sp_sp'>-</span> 회의실을 깨끗하게 사용해주십시오.</p>
              </div>
            </div>
            {/*
            <div className="information_announce">
              <div className="information_inner">
                <h4>For more info</h4>
              </div>
              <div className="information_inner">
                <p className='mb10 sp_p'><span className='sp_sp'>-</span> Jeffrey Fairbanks - <a href="mailto:j.fairbanks@cheil.com" class="ssone">j.fairbanks@cheil.com</a></p>
              </div>
            </div>
            */}
          </section>
        )
      }
    </>
  );
}
