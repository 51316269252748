const faqKR = [
  {
    categoryCode: 'use',
    category: '이용방법',
    question: '[로그인] 로그인을 하지 않으면 사이트 이용이 불가능한가요?',
    answer: '본 사이트는 회원 로그인을 기반으로 하는 사이트로, 반드시 로그인을 해주셔야 이용이 가능합니다. 회원이 아니신 분들은 보유하고 계신 삼성닷컴 계정 주소를 통해 회원가입을 완료한 후 이용해 주시기 바랍니다.',
  },
  {
    categoryCode: 'use',
    category: '이용방법',
    question: '[회원가입] 회원 가입은 삼성닷컴 이메일로만 가능한가요?',
    answer: '삼성 임직원 및 협력사를 대상으로 운영되는 사이트로 삼성닷컴, (파트너)삼성닷컴 아이디로만 신청이 가능합니다. 신청 시 입력 해 주신 이메일 주소로 승인 메일이 발송되니 등록하신 이메일을 확인하시기 바랍니다.',
  },
  {
    categoryCode: 'use',
    category: '이용방법',
    question: '[회원가입] Ticket, Business lounge, VIP tour의 각 메뉴별로 가입을 따로 진행해야 하나요?',
    answer: '아니오. 비즈니스 라운지와 VIP 투어 예약 신청 두 가지는 본 통합 사이트에서 함께 가능하여 한 번만 진행해주시면 됩니다.<br>추가로 CES 2025 출입 뱃지 신청은 별도 전달된 안내에 따라 타사이트에서 진행해주시기 바랍니다.',
  },
  {
    categoryCode: 'use',
    category: '이용방법',
    question: '[회원가입] CES, MWC, IFA 등 각 행사마다 회원 가입을 새로 해야 하나요?',
    answer: '네. 행사 별 회원가입 정보를 보관하지 않으며, 사이트가 행사마다 별도 운영되기 때문에 각각 가입을 해주셔야 합니다.',
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[이용방법] 티켓 신청 단계는 어떻게 되나요?',
    answer: `시간 차를 두고, 총 두 번의 신청 과정을 거쳐야 합니다. (1) 아래 주소에서(<a class="ssone" href="${window.location.origin}" tagret="_blank">${window.location.origin}</a>) 회원 가입하여, 바우처 코드를 신청합니다. (2) 2차 발급일에 안내 메일을 통해 티켓 신청 링크가 전달 될 예정입니다.<br/>해당 코드를 직접 IFA 공식 티켓 사이트에 등록하셔서 PDF 형태의 티켓을 최종 발급받으셔야 합니다.<br/>티켓을 마지막 IFA 단계까지 발급받아 오시지 않은 경우, 현장에서는 티켓 발권이 불가능한 점 유의 부탁드립니다.
    `,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[이용방법] 베를린 IFA 행사장 입장 시에, 어떤 것들이 필요한가요?',
    answer: `IFA 공식 티켓 사이트에서 발권 받은 티켓은 PDF 프린트 혹은 모바일 기기 내 저장하여 행사장에 지참 부탁드립니다.`,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[이용방법] 언제 신청 링크가 배포되나요?',
    answer: `공지된 발급일에 발급됩니다. 바우처 코드 발급 시, Ticket Request에서 등록하신 메일 주소로 안내 메일이 발송됩니다.`,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[이용방법] 티켓 신청 시, 별도의 비용이 발생하나요?',
    answer: `아니오. 본 행사는 임직원을 대상으로 진행되므로 별도의 비용이 발생하지 않습니다.`,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[발급대상] 티켓 발급 대상 범위가 궁금합니다.',
    answer: `해당 티켓은 IFA 23 삼성전자 전시 관련 업무를 진행하는 출장 임직원이 신청 가능한 티켓입니다. 삼성관 전시 관련 업무를 위한 신청이 아닌 경우, 혹은 임직원 외의 인원을 위한 신청은 금지합니다.<br/>또한, 별도 법인 담당자측 티켓은 지원하지 않습니다.`,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[발급대상] 티켓 발급 대상자인데도, 바우처 코드를 발급받지 못했습니다. 추가로 신청할 수 있는 방법이 있나요?',
    answer: `2차 바우처 코드 발급일에도 바우처 코드 승인 메일을 받지 못하신 경우, 담당자 (제일기획 김은섭 프로)에게 연락 부탁드립니다.`,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[대리신청] 대리 신청이 가능한가요?',
    answer: `네. 아래 주소에서(<a class="ssone" href="${window.location.origin}" tagret="_blank">${window.location.origin}</a>), Ticket Request 페이지 하단의 [+] 버튼을 통해 신청자 정보를 추가하는 방식으로 대리 신청이 가능합니다. 삼성 이벤트 사이트 안내 메일의 경우, 대상자가 아닌 신청자의 메일 주소로 메일링 서비스가 등록되오니, 추후 IFA 공식 티켓 사이트에서 대리 등록하실 경우, 이 점 유의 바랍니다. 또한, 허위 또는 삼성 관계자 외의 인원 대리 신청 시에, 사업부 청구 및 해당 사항 통보 예정이니 유의 바랍니다.`,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[대리신청] 대리 신청 시 유의할 점이 있나요?',
    answer: `IFA 공식 티켓 사이트에서 바우처 코드를 등록할 때, 신청 대상자의 메일로 6자리 코드를 인증하는 단계가 있습니다. 빠른 신청을 위해, IFA 단계에서 신청자와 대상자 간 연락 가능 상태를 유지해 주시기 바랍니다. `,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[티켓타입] [Exhibiting Client]과 [Stand Maintenance badges]의 차이점이 무엇인가요?',
    answer: `[Exhibiting Client]은 전시 기간 및 준비기간 중 출입이 가능하며, [Stand Maintenance badges]은 전시 기간을 제외한 공사, 철거 기간 동안의 출입이 가능합니다. 신청 링크에서 필요한 티켓으로 신청해주시면 됩니다.`,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[티켓타입] [Exhibiting Client]과 [Stand Maintenance badges] 중 어떤 것을 발급받아야 하나요?',
    answer: `
[Exhibiting Client](전시 기간 및 준비기간 중 출입)은 [Stand Maintenance badges](전시 기간 전 후 출입) 권한을 포함하고 있습니다.<br/>준비기간에만 출입이 필요한 인원의 경우, (Stand Maintenance badges만 필요하신 경우) Stand Maintenance badges 체크 후 발급 부탁드립니다.`,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[티켓타입] IFA 공식 전시 기간 (9월 1일~9월 5일) 외의 기간에도, 행사장 출입이 가능한가요?',
    answer: `네. [Exhibiting Client](전시 기간 및 준비기간 중 출입)은 [Stand Maintenance badges](전시 기간 전 후 출입) 권한을 포함하고 있습니다. 전시 기간 전후 (~8월 31일 / 9월 5일 오후 6시~)의 공사 기간 동안, 출입 가능 시간제한 없이 입장이 가능합니다.
단, 심야 근무의 경우 미리 주최 측에 공유 부탁드립니다.`,
  },
  // {
  //   categoryCode: 'ticket',
  //   category: 'Ticket',
  //   question: '[티켓타입] [Exhibitor Ticket]과 [Build-up Ticket] 바우처 코드를 모두 받았습니다. 하나만 필요한 경우에는, IFA 공식 티켓 사이트에 하나만 등록해서 사용해도 괜찮은가요?',
  //   answer: `네. 두 가지 코드 중 필요하신 코드만 등록하여 사용하시는 것이 가능합니다.`,
  // },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[예약내용취소] 출장이 취소되었습니다. 취소는 어떻게 하나요?',
    answer: `본 홈페이지 내에서의 신청만 완료한 경우, 별도의 취소 프로세스는 필요하지 않습니다. 다만 공식 등록 사이트에서의 취소는 불가능하며 각 사업부 비용 청구시에 포함될 예정이므로, 출장 확정 후 신중하게 진행 부탁드립니다.
    `,
  },
  {
    categoryCode: 'ticket',
    category: 'Ticket',
    question: '[예약내용수정] 신청자 정보를 잘못 입력하였습니다. 어떻게 수정할 수 있나요?',
    answer: `삼성 이벤트 페이지에서 잘못 입력하신 경우, 아래 주소에서(<a class="ssone" href="${window.location.origin}" tagret="_blank">${window.location.origin}</a>) 블랙 배경의 cancel 버튼인 경우에 한하여, 취소 후 재 등록 부탁드립니다. cancel 버튼이 회색 배경으로,
클릭이 불가능한 경우에는, 담당자 (제일기획 김은섭 프로)에게 연락 부탁드립니다.`,
  },
  {
    categoryCode: 'room',
    category: 'Business lounge',
    question: '[이용방법] 회의실 예약 시 별도의 비용이 발생하나요?',
    answer: '아니오. 본 행사는 임직원을 대상으로 진행되므로 별도의 비용이 발생하지 않습니다. ',
  },
  {
    categoryCode: 'room',
    category: 'Business lounge',
    question: '[이용방법] 회의실 예약 시 하루에 몇 시간 이용 가능한가요?',
    answer: '회의실은 최대 한 시간 이용이 가능합니다. 또한, 하루 최대 1건만 신청 가능합니다. 기본 30분 단위로 예약이 가능하니 이 점 참고 부탁드립니다. ',
  },
  {
    categoryCode: 'room',
    category: 'Business lounge',
    question: '[신청방법] 회의실 예약은 어떻게 진행되나요?',
    answer: `예약자는 아래 주소에서(<a href="${window.location.origin}/room/reservation/timetable" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/room/reservation/timetable</a>) 이용 가능한 시간을 클릭한 후에 예약 페이지에서 필수 항목을 작성해 주시면 됩니다. 예약자가 신청 완료 시, 관리자를 통해 예약이 최종 승인됩니다. 예약이 최종 승인되면 승인 메일이 신청하신 이메일로 발송되니 등록하신 이메일을 확인하시기 바랍니다.<br>승인 메일 발송은 현지 상황 최종 반영 후, 12월 말-1월 초경 발송될 예정이오니 참고 부탁드립니다.<br>일정 변동 시 원활한 비즈니스 라운지 운영을 위해 빠른 수정/변경 부탁드립니다.`,
  },
  {
    categoryCode: 'room',
    category: 'Business lounge',
    question: '[예약확인] 내 예약 신청 현황을 알고 싶습니다.',
    answer: `예약이 완료되면 아래 주소에서(<a href="${window.location.origin}/room/application-list" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/room/application-list</a>) 예약에 관한 정보, 진행 상황을 확인할 수 있습니다. 또한, 예약을 완료하신 경우, 관리자의 승인을 거쳐 입력하신 이메일 주소로 승인 완료 메일이 발송됩니다.`,
  },
  {
    categoryCode: 'room',
    category: 'Business lounge',
    question: '[예약내용수정] 정보를 잘못 기재했습니다. 수정은 어떻게 하나요?',
    answer: `아래 주소(<a href="${window.location.origin}/room/application-list" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/room/application-list</a>)의 상세페이지에서 입력하신 정보를 수정하거나 예약 취소를 할 수 있습니다. 단, 승인 이후에는 예약 날짜 및 시간과 회의실은 변경이 불가하기에 취소 후 재 예약하실 수 있습니다. 신중하게 예약해 주시기 바랍니다.`,
  },
  {
    categoryCode: 'room',
    category: 'Business lounge',
    question: '예약만 하면 이용할 수 있는 건가요?',
    answer: '예약 후, 관리자의 승인을 통해 이용이 가능하며, 예약 상황에 따라 요청이 반려될 수 있습니다. 시간 조정이 필요한 경우 담당자가 따로 연락을 드립니다. 예약이 최종 승인되면 승인 메일이 신청하신 이메일로 발송되니 등록하신 이메일을 확인하시기 바랍니다. 승인 메일 발송은 현지 상황 최종 반영 후, 12월 말-1월 초경 발송될 예정이오니 참고 부탁드립니다. 일정 변동 시 원활한 비즈니스 라운지 운영을 위해 빠른 수정/변경 부탁드립니다.',
  },
  {
    categoryCode: 'vip',
    category: 'VIP tour',
    question: 'VIP tour 예약 시, 별도의 비용이나 자격요건이 있나요?',
    answer: 'VIP tour는 별도의 비용이 발생하지 않습니다만, 부사장급/지역총괄급 이상 삼성 임원이 직접 호스팅 하시는 투어에 한해 신청이 가능합니다.',
  },
  {
    categoryCode: 'vip',
    category: 'VIP tour',
    question: 'VIP tour 예약은 어떻게 진행되나요?',
    answer: `예약은 이 주소에서(<a href="${window.location.origin}/viptour/reservation" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/viptour/reservation</a>) 신청하실 수 있습니다. 신청 양식을 제출하시더라도, 관리자가 예약을 최종 승인하여야 예약이 완료됩니다. 예약이 최종 승인되면 승인 메일이 신청하신 이메일로 발송되니 등록하신 이메일을 확인하시기 바랍니다.`,
  },
  {
    categoryCode: 'vip',
    category: 'VIP tour',
    question: '참가자는 최대 몇명까지 등록 가능한가요?',
    answer: '게스트 분들의 쾌적한 투어를 위하여 회당 투어 인원은 15인으로 제한하고 있습니다.',
  },
  {
    categoryCode: 'vip',
    category: 'VIP tour',
    question: 'VIP tour 예약 시, 언어 선택이 가능한가요?',
    answer: '네, 가능합니다. 한국어/영어 두가지 중 한가지를 선택할 수 있습니다.',
  },
  {
    categoryCode: 'vip',
    category: 'VIP tour',
    question: '내 예약 신청 현황을 알고 싶습니다.',
    answer: `예약이 완료되면 이 주소에서(<a href="${window.location.origin}/viptour/reservation-list" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/viptour/reservation-list</a>)예약에 관한 정보, 진행 상황을 확인할 수 있습니다. 또한, 예약을 완료하신 경우, 관리자의 승인을 거쳐 입력하신 이메일 주소로 승인 완료 메일이 발송됩니다.`,
  },
  {
    categoryCode: 'vip',
    category: 'VIP tour',
    question: '정보를 잘못 기재했습니다. 수정은 어떻게 하나요?',
    answer: `최종 확정 전이라면 이 주소(<a href="${window.location.origin}/viptour/reservation-list" style="color:#1268bd;text-decoration:underline" target="_blank">${window.location.origin}/viptour/reservation-list</a>)상세페이지에서 입력하신 정보를 수정하거나 예약 취소를 할 수 있습니다. 부득이한 사정으로 최종 확정된 예약 일시에 방문이 어려우신 경우, 담당자(황혜리 프로 : <a href="mailto:h0302.hwang@samsung.com" style="color:#1268bd;text-decoration:underline">h0302.hwang@samsung.com</a>)측에 반드시 연락 바랍니다.`,
  }
];

export default faqKR;
