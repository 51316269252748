import { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import history from '@/helpers/history';
import Moment from 'react-moment';
import { reservation } from '@/actions/room';
import { setIsLoading } from '../../actions/common';

export default function ReservationDetail() {
  const dispatch = useDispatch();
  const params = useParams()

  const componentRef = useRef(null);
  const [currentItem, setCurrentItem] = useState(null);

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(reservation(params.book_id))
    .then((res) => {
      setCurrentItem(res);
      dispatch(setIsLoading(false));
    })
    .catch(message => {
      history.navigate('/room/application-list', {replace: true})
    });
  }, []);

  return (
    <>
    <div className="container_top ticket_top">
        <div className="top-info top-info_cn">
          <h2 className="top-title">Business lounge reservation</h2>
        </div>
    </div>
    <div className="container_body-tit">
      <h3></h3>
      <ReactToPrint
        trigger={() => <span className="tit-desc"><button className="btn-print"></button></span>}
        content={() => componentRef.current}
      />
    </div>
    {currentItem && (
      <section className="" ref={componentRef}>
        <form className="form">
          <div className="vip-reservation-tit mb60">
            <h4>Reservation</h4>
            <span className="tit-desc"></span>
          </div>
          <div className="form_inner mb100">
            <div className="form_item">
              <label className="list_label">Date & Time<span></span>날짜 & 시간</label>
              <div className="form_info" style={{alignItems:'center'}}>
                <p className="list_info">
                  <Moment format='DD.MMM.YYYY'>{currentItem.dateLabel}</Moment>
                </p>
                <span>From</span>
                <p className="list_info">{currentItem.startTimeLabel}</p>
                <span>To</span>
                <p className="list_info">{currentItem.endTimeLabel}</p>
              </div>
            </div>
            <div className="form_item">
              <label htmlFor="host_dept" className="list_label">Room name<span></span>회의실명</label>
              <p className="list_info">{currentItem.room}</p>
            </div>
            <div className="form_item">
              <label htmlFor="host_dept" className="list_label">Meeting title<span></span>회의명</label>
              <p className="list_info">{currentItem.title}</p>
            </div>
          </div>
          <div className="vip-reservation-tit mb60">
            <h4>Host</h4>
          </div>
          <div className="form_inner mb100">
            <div className='rsvroom_items'>
              <div className="form_item">
                <label htmlFor="roomhost_name" className="list_label">Name<span></span>이름</label>
                <p className="list_info">{currentItem.host.firstName} {currentItem.host.lastName}</p>
              </div>
              <div className="form_item">
                <label htmlFor="memb_region" className="list_label">Region/Organization<span></span>지역/법인</label>
                <p className="list_info">{currentItem.host.region}/{currentItem.host.organization_other||currentItem.host.organization}</p>
              </div>
              <div className="form_item">
                <label htmlFor="memb_org" className="list_label">Job<span></span>직업</label>
                <p className="list_info">{currentItem.host.job}</p>
              </div>
              <div className="form_item">
                <label htmlFor="memb_email" className="list_label">Email<span></span>이메일</label>
                <p className="list_info">{currentItem.host.email}</p>
              </div>
              <div className="form_item">
                <label htmlFor="memb_ph" className="list_label">Mobile contact point<span></span>연락처</label>
                <p className="list_info">{currentItem.host.countryCode} {currentItem.host.mobile}</p>
              </div>   

            </div>
          </div>
          <div className="vip-reservation-tit mb60">
            <h4>Attendees</h4>
          </div>
          <div className="form_inner mb100">
            <div className='rsvroom_items'>
              <div className="form_item">
                <label htmlFor="atte_memb" className="list_label">Name<span></span>이름</label>
                <p className="list_info">{currentItem.attendee.firstName} {currentItem.attendee.lastName}</p>
              </div>
              <div className="form_item">
                <label htmlFor="att_company" className="list_label">Company<span></span>회사</label>
                <p className="list_info">{currentItem.attendee.company}</p>
              </div>
              <div className="form_item">
                <label htmlFor="meeting_type" className="list_label">Meeting type / Attendance<span></span>회의타입/참석자</label>
                <p className="list_info">{currentItem.attendee.meetingType}<span></span>{currentItem.attendee.attendeeCount}</p>
              </div>
            </div>
          </div>
          <div className="vip-reservation-tit mb60">
            <h4>Business lounge applicant</h4>
          </div>
          <div className="form_inner mb80">
            <div className='rsvroom_items'>
              <div className="form_item">
                <label htmlFor="atte_memb" className="list_label">Name<span></span>이름</label>
                <p className="list_info">{currentItem.booker.firstName} {currentItem.booker.lastName}</p>
              </div>
              <div className="form_item">
                <label htmlFor="memb_region" className="list_label">Region/Organization<span></span>지역/법인</label>
                <p className="list_info">{currentItem.booker.region}/{currentItem.booker.organization_other|| currentItem.booker.organization}</p>
              </div>
              <div className="form_item">
                <label htmlFor="memb_org" className="list_label">Job<span></span>직업</label>
                <p className="list_info">{currentItem.booker.job}</p>
              </div>
              <div className="form_item">
                <label htmlFor="memb_email" className="list_label">Email<span></span>이메일</label>
                <p className="list_info">{currentItem.booker.email}</p>
              </div>
              <div className="form_item">
                <label htmlFor="memb_ph" className="list_label">Mobile contact point<span></span>연락처</label>
                <p className="list_info">{currentItem.booker.countryCode} {currentItem.booker.mobile}</p>
              </div>   

            </div>
          </div>
          <span className="bar mb60"></span>
          <div className="buttons_center">
            <Link to="/room/application-list" replace={true}><button type="button" className="btn-back"><span className="spsans">List</span></button></Link>
          </div>
        </form>   
      </section>
    )}
    </>
  );
}
