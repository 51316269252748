import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import regionData from '@/data/regionData';
import mobileData from '@/data/mobileData';
import companyData from '@/data/companyData';
import {jobs, meetingTypes} from '@/data/conditions';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { rooms } from '@/data/meetingroom';
import _ from 'lodash';
import history from '@/helpers/history';
import Moment from 'react-moment';

import { me } from '@/actions/auth';
import { updateReservation, reservation } from '@/actions/room';
import { setIsLoading } from '@/actions/common';

export default function ReservationEdit() {
  const dispatch = useDispatch();
  const params = useParams();

  const validationSchema = Yup.object().shape({
    // date: Yup.string().required('Required field'),
    // startTime: Yup.string().required('Required field'),
    // endTime: Yup.string().required('Required field'),
    // room: Yup.string().required(),
    title: Yup.string().required('Required field'),
    host: Yup.object().shape({
      firstName: Yup.string().required('Required field'),
      lastName: Yup.string().required('Required field'),
      region: Yup.string().required('Required field'),
      organization: Yup.string().required('Required field'),
      organization_other: Yup.string().when("host.organization", {
        is: 'Other',
        then: Yup.string().required("Required field")
      }),
      job: Yup.string().required('Required field'),
      email: Yup.string().email('Email is Invalid').required('Required field'),
      countryCode: Yup.string().required('Required field'),
      mobile: Yup.string().required('Required field')
    }),
    attendee: Yup.object().shape({
      firstName: Yup.string().required('Required field'),
      lastName: Yup.string().required('Required field'),
      company: Yup.string().required('Required field'),
      meetingType: Yup.string().required('Required field'),
      attendeeCount: Yup.string().required('Required field')
    }),
    booker: Yup.object().shape({
      firstName: Yup.string().required('Required field'),
      lastName: Yup.string().required('Required field'),
      region: Yup.string().required('Required field'),
      organization: Yup.string().required('Required field'),
      organization_other: Yup.string().when("booker.organization", {
        is: 'Other',
        then: Yup.string().required("Required field")
      }),
      job: Yup.string().required('Required field'),
      email: Yup.string().email('Email is Invalid').required('Required field'),
      countryCode: Yup.string().required('Required field'),
      mobile: Yup.string().required('Required field')
    })
  });

  const formOptions = {
    resolver: yupResolver(validationSchema)
  };

  const { register, control, handleSubmit, setValue, setError, reset, formState, watch } = useForm(formOptions);
  const { errors } = formState;

  const [currentItem, setCurrentItem] = useState(null);
  const [isHostSameProfile, setIsHostSameProfile] = useState(false);
  const [isBookerSameProfile, setIsBookerSameProfile] = useState(false);

  const host = watch('host');
  const attendee = watch('attendee');
  const booker = watch('booker');

  // useEffect(() => {
  //   if(host?.organization){
  //     setValue('host.countryCode', regionData[host?.region][host?.organization])
  //   }
  // }, [host?.organization])

  // useEffect(() => {
  //   if(booker?.organization){
  //     setValue('booker.countryCode', regionData[booker?.region][booker?.organization])
  //   }
  // }, [booker?.organization])

  useEffect(() => {
    if(isHostSameProfile){
      dispatch(me())
      .then((res) => {
        setValue(`host`, {
          ...currentItem.host,
          firstName: res.firstName,
          lastName: res.lastName,
          email: res.email,
          // company: res.company,
          region: res.region,
          organization: res.organization,
          organization_other: res.organization_other,
          countryCode: res.countryCode,
          mobile: res.mobile
        }, { shouldValidate: true })
      })
    } else {
      setValue(`host`, {
        ...currentItem?.host,
        firstName: '',
        lastName: '',
        email: '',
        // company: '',
        region: '',
        organization: '',
        organization_other: '',
        countryCode: '',
        mobile: ''
      })
    }
  }, [isHostSameProfile])

  useEffect(() => {
    if(isBookerSameProfile){
      dispatch(me())
      .then((res) => {
        setValue(`booker`, {
          ...currentItem.host,
          firstName: res.firstName,
          lastName: res.lastName,
          email: res.email,
          // company: res.company,
          region: res.region,
          organization: res.organization,
          organization_other: res.organization_other,
          countryCode: res.countryCode,
          mobile: res.mobile
        }, { shouldValidate: true })
      })
    } else {
      setValue(`booker`, {
        ...currentItem?.host,
        firstName: '',
        lastName: '',
        email: '',
        // company: '',
        region: '',
        organization: '',
        organization_other: '',
        countryCode: '',
        mobile: ''
      })
    }
  }, [isBookerSameProfile])

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(reservation(params.book_id))
    .then((res) => {
      // console.log('res',res)
      reset(res);
      setCurrentItem({
        ...res,
        capacity: _.find(rooms, row=>row.room === res.room)?.capacity || 0
      });
      dispatch(setIsLoading(true));
    })
  }, []);

  const onSubmit = (data) => {
    dispatch(updateReservation({
      id: params.book_id,
      ...data
    }))
    .then((res) => {
      history.navigate('/room/application-list', {replace: true})
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  const onChangeHostSameProfileCheckbox = (e) => {
    if(e.target.checked){
      setIsHostSameProfile(true);
    }else{
      setIsHostSameProfile(false);
    }
  }

  const onChangeBookerSameProfileCheckbox = (e) => {
    if(e.target.checked){
      setIsBookerSameProfile(true);
    }else{
      setIsBookerSameProfile(false);
    }
  }

  return (
    <>
      <div className="container_top ticket_top">
        <div className="top-info top-info_cn">
          <h2 className="top-title">Business lounge reservation</h2>
        </div>
      </div>
      <div className='container_body-tit'>
        <h3></h3>
        <span className="tit-desc-box">* All fields are required </span>
      </div>
      {
        currentItem && (<section className='ticket-request'>
        <form className='form' onSubmit={handleSubmit(onSubmit)}>
          <div className="vip-reservation-tit mb60">
            <h4>Reservation</h4>
            <span className="tit-desc"></span>
          </div>
          <div className="form_inner mp-input1-00 mb100">
            <div className="form_item">
              <label className="list_label"><span class="tit-desc-p">*</span>Date & Time<span></span>날짜 & 시간</label>
              <div className="form_info" style={{alignItems:'center'}}>
                <p className="list_info">
                  <Moment format='DD.MMM.YYYY'>{currentItem.dateLabel}</Moment>
                </p>
                <span>From</span>
                <p className="list_info">{currentItem.startTimeLabel}</p>
                <span>To</span>
                <p className="list_info">{currentItem.endTimeLabel}</p>
              </div>
            </div>
            <div className="form_item">
              <label htmlFor="host_dept" className="list_label"><span class="tit-desc-p">*</span>Room name<span></span>회의실명</label>
              <div className="form_info">
                <p className="list_info">{currentItem.room}</p>
              </div>
            </div>
            <div className="form_item">
              <label htmlFor="title" className="list_label"><span class="tit-desc-p">*</span>Meeting title<span></span>회의명</label>
              <div className={`${errors.title?.message ? 'is-invalid' : ''}`}>
                <input
                  type='text'
                  id="title"
                  name="title"
                  {...register('title',{
                    onChange: (e)=>{
                      e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣0-9a-zA-Z _.-]/g,'');
                    }})}
                  placeholder='Enter your meeting title'
                  maxLength="30"
                />
                <div className='invalid-feedback'>{errors.title?.message}</div>
              </div>
            </div>
          </div>
          <div className="vip-reservation-tit mb60">
            <h4>Host</h4>
            <div className="checkbox">
              <input type="checkbox" name="same" id="same" onChange={onChangeHostSameProfileCheckbox} className='mr10' checked={isHostSameProfile} />
              <label htmlFor="same">Same as my profile</label>
            </div>
          </div>
          <div className="form_inner mb100">
            <div className='rsvroom_items'>
            <div className="form_item">
              <label htmlFor="roomhost_name" className="list_label"><span class="tit-desc-p">*</span>Name<span></span>이름</label>
              <div className='register_name'>
                <div>
                  <div className={`${errors.host?.firstName?.message ? 'is-invalid' : ''}`}>
                      <input
                        type='text'
                        id="host.firstName"
                        name="host.firstName"
                        maxLength="20"
                        {...register('host.firstName',{
                          onChange: (e)=>{
                            e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                          }})}
                        placeholder='First name'
                      />
                      <div className='invalid-feedback'>{errors.host?.firstName?.message}</div>
                  </div>

                </div>
                <div>
                  <div className={`${errors.host?.lastName?.message ? 'is-invalid' : ''}`}>
                    <input
                      type='text'
                      id="host.lastName"
                      name="host.lastName"
                      maxLength="20"
                      {...register('host.lastName',{
                        onChange: (e)=>{
                          e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                        }})}
                      placeholder='Last name'
                    />
                    <div className='invalid-feedback'>{errors.host?.lastName?.message}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form_item">
              <label htmlFor="host.region" className="list_label"><span class="tit-desc-p">*</span>Region<span></span>지역</label>
              <div className={`${errors.host?.region?.message ? 'is-invalid' : ''}`}>
                <select id="host.region" name="host.region" {...register('host.region')}>
                  <option value=''>please select</option>
                  {
                    Object.keys(regionData).map(key => (
                      <option value={key}>{key}</option>
                    ))
                  }
                </select>
                <div className='invalid-feedback'>{errors.host?.region?.message}</div>
              </div>
            </div>
            <div className="form_item">
              <label htmlFor="host.organization" className="list_label"><span class="tit-desc-p">*</span>Organization<span></span>법인</label>
              <div className={`${errors.host?.organization?.message ? 'is-invalid' : ''}`}>
                <select id="host.organization" name="host.organization" defaultValue={host.organization} value={host.organization} {...register('host.organization',{
                        onChange: (e)=>{
                          setValue('host.organization_other', '')
                          setValue('host.countryCode', regionData[host?.region][e.target.value])
                        }
                      })}>
                  <option value=''>please select</option>
                  {
                    host?.region && Object.keys(regionData[host?.region]).map(key => (
                      <option value={key}>{key}</option>
                    ))
                  }
                </select>
                <div className='invalid-feedback'>{errors.host?.organization?.message}</div>
              </div>
            </div>
            {host.organization === 'Other' && <div className={`form_item other__wrap`}>
              <label htmlFor="memb_org" className="list_label" style={{visibility:'hidden'}}>Other<span></span>기타</label>
                <div className={`${errors.host?.organization_other?.message ? 'is-invalid' : ''}`}>
                    <input
                      type='text'
                      id="host.organization_other"
                      name="host.organization_other"
                      maxLength="20"
                      {...register('host.organization_other')}
                    placeholder="Organization/법인"
                    />
                  <div className='invalid-feedback'>{errors.host?.organization_other?.message}</div>
              </div>
            </div>}
            <div className="form_item">
              <label htmlFor="host.job" className="list_label"><span class="tit-desc-p">*</span>Job<span></span>직업</label>
              <div className={`${errors.host?.job?.message ? 'is-invalid' : ''}`}>
                <select id="host.job" name="host.job" {...register('host.job')}>
                  <option value=''>please select</option>
                  {
                    jobs.map(job => (
                      <option value={job}>{job}</option>
                    ))
                  }
                </select>
                <div className='invalid-feedback'>{errors.host?.job?.message}</div>
              </div>
            </div>
            <div className="form_item">
              <label htmlFor="host.email" className="list_label"><span class="tit-desc-p">*</span>Email<span></span>이메일</label>
              <div className={`${errors.host?.email?.message ? 'is-invalid' : ''}`}>
                <input
                  type='email'
                  id={`host.email`}
                  name={`host.email`}
                  {...register(`host.email`,{
                    onChange: (e)=>{
                      e.target.value = e.target.value.replace(/[^0-9a-zA-Z-_.@]/g,'').toLowerCase();
                    }})}
                  placeholder='Enter your email'
                />
                <div className='invalid-feedback'>{errors.host?.email?.message}</div>
              </div>
            </div>
            <div className="form_item mpMobile-phone">
              <label htmlFor="host.countryCode" className="list_label"><span class="tit-desc-p">*</span>Mobile contact point<span></span>연락처</label>
              <div className='form_item__wrap'>
                <div className={`${errors.host?.countryCode?.message ? 'is-invalid' : ''}`}>
                  <select id="host.countryCode" name="host.countryCode" defaultValue={host.countryCode} value={host.countryCode} {...register('host.countryCode')}>
                    <option value=''>please select</option>
                    {
                      mobileData.map(value => (
                        <option value={value}>{value}</option>
                      ))
                    }
                  </select>
                  <div className='invalid-feedback'>{errors.host?.countryCode?.message}</div>
                </div>
                <div className={`${errors.host?.mobile?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='text'
                    id="host.mobile"
                    name="host.mobile"
                    {...register('host.mobile',{
                      onChange: (e)=>{
                        e.target.value = e.target.value.replace(/[^0-9]/g,'');
                        // if(e.target.value.length > 0 && e.target.value.substring(0,1) === '0') {
                        //   e.target.value = e.target.value.substring(1,e.target.value.length)
                        // }
                    }})}
                    placeholder='Enter your mobile number'
                  />
                  <div className='invalid-feedback'>{errors.host?.mobile?.message}</div>
                </div>

              </div>
            </div>
            </div>
          </div>
          <div className="vip-reservation-tit mb60">
            <h4>Attendees</h4>
          </div>
          <div className="form_inner mb100">
            <div className='rsvroom_items'>
              <div className="form_item">
                <label htmlFor="attendee.firstName" className="list_label"><span class="tit-desc-p">*</span>Name<span></span>이름</label>
                <div className='register_name'>
                  <div>
                    <div className={`${errors.attendee?.firstName?.message ? 'is-invalid' : ''}`}>
                        <input
                          type='text'
                          id="attendee.firstName"
                          name="attendee.firstName"
                          maxLength="20"
                          {...register('attendee.firstName',{
                            onChange: (e)=>{
                              e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                            }})}
                          placeholder='First name'
                        />
                        <div className='invalid-feedback'>{errors.attendee?.firstName?.message}</div>
                    </div>

                  </div>
                  <div>
                    <div className={`${errors.attendee?.lastName?.message ? 'is-invalid' : ''}`}>
                      <input
                        type='text'
                        id="attendee.lastName"
                        name="attendee.lastName"
                        maxLength="20"
                        {...register('attendee.lastName',{
                          onChange: (e)=>{
                            e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                          }})}
                        placeholder='Last name'
                      />
                      <div className='invalid-feedback'>{errors.attendee?.lastName?.message}</div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="form_item">
                <label htmlFor="attendee.company" className="list_label"><span class="tit-desc-p">*</span>Company<span></span>회사</label>
                <div className={`${errors.attendee?.company?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='text'
                    id="attendee.company"
                    name="attendee.company"
                    {...register('attendee.company',{
                      onChange: (e)=>{
                        e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣0-9a-zA-Z _.-]/g,'');
                      }})}
                    placeholder='Enter your company'
                    maxLength="30"
                  />
                  <div className='invalid-feedback '>{errors.attendee?.company?.message}</div>
                </div>
              </div>
              <div className="form_item" style={{width: '100%'}}>
                <label htmlFor="attendee.meetingType" className="list_label"><span class="tit-desc-p">*</span>Meeting type / Attendance<span></span>회의타입 / 참석자</label>
                <div className='request_meeting'>
                  <div style={{width: 499}}>
                    <select id="attendee.meetingType" name="attendee.meetingType" {...register('attendee.meetingType')} style={{width: 499}}>
                      <option value="">please select</option>
                      {
                          meetingTypes.map(type => (
                            <option value={type}>{type}</option>
                          ))
                      }
                    </select>
                    
                  </div>
                  <div style={{width: 499}}>
                    <select id="attendee.attendeeCount" name="attendee.attendeeCount" {...register('attendee.attendeeCount')}>
                      {
                        Array.from(Array(currentItem.capacity).keys()).map(i=>(
                          <option value={i+1}>{i+1}</option>
                        ))
                      }
                    </select>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="vip-reservation-tit mb60">
            <h4>Business lounge applicant</h4>
            <div className="checkbox">
              <input type="checkbox" name="same" id="same" onChange={onChangeBookerSameProfileCheckbox} className='mr10' checked={isBookerSameProfile} />
              <label htmlFor="same">Same as my profile</label>
            </div>
          </div>
          <div className="form_inner mb80">
            <div className='rsvroom_items'>
              <div className="form_item">
                <label htmlFor="booker.firstName" className="list_label"><span class="tit-desc-p">*</span>Name<span></span>이름</label>
                <div className='register_name'>
                  <div>
                    <div className={`${errors.host?.firstName?.message ? 'is-invalid' : ''}`}>
                        <input
                          type='text'
                          id="booker.firstName"
                          name="booker.firstName"
                          maxLength="20"
                          {...register('booker.firstName',{
                            onChange: (e)=>{
                              e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                            }})}
                          placeholder='First name'
                        />
                        <div className='invalid-feedback'>{errors.host?.firstName?.message}</div>
                    </div>
                  </div>
                  <div>
                    <div className={`${errors.host?.lastName?.message ? 'is-invalid' : ''}`}>
                      <input
                        type='text'
                        id="booker.lastName"
                        name="booker.lastName"
                        maxLength="20"
                        {...register('booker.lastName',{
                          onChange: (e)=>{
                            e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z _.-]/g,'');
                          }})}
                        placeholder='Last name'
                      />
                      <div className='invalid-feedback'>{errors.host?.lastName?.message}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form_item">
                <label htmlFor="booker.region" className="list_label"><span class="tit-desc-p">*</span>Region<span></span>지역</label>
                <div className={`${errors.booker?.region?.message ? 'is-invalid' : ''}`}>
                  <select id="booker.region" name="booker.region" {...register('booker.region')}>
                    <option value=''>please select</option>
                    {
                      Object.keys(regionData).map(key => (
                        <option value={key}>{key}</option>
                      ))
                    }
                  </select>
                  <div className='invalid-feedback'>{errors.booker?.region?.message}</div>
                </div>
              </div>
              <div className="form_item">
                <label htmlFor="booker.organization" className="list_label"><span class="tit-desc-p">*</span>Organization<span></span>법인</label>
                <div className={`${errors.booker?.organization?.message ? 'is-invalid' : ''}`}>
                  <select id="booker.organization" name="booker.organization" defaultValue={booker.organization} value={booker.organization}  {...register('booker.organization',{
                        onChange: (e)=>{
                          setValue('booker.organization_other', '')
                          setValue('booker.countryCode', regionData[booker?.region][e.target.value])
                        }
                      })}>
                    <option value=''>please select</option>
                    {
                      booker?.region && Object.keys(regionData[booker?.region]).map(key => (
                        <option value={key}>{key}</option>
                      ))
                    }
                  </select>
                  <div className='invalid-feedback'>{errors.booker?.organization?.message}</div>
                </div>
              </div>
              {booker.organization === 'Other' && <div className={`form_item other__wrap ${errors.booker?.organization_other?.message ? 'is-invalid' : ''}`}>
                <label htmlFor="memb_org" className="list_label" style={{visibility:'hidden'}}>Other<span></span>기타</label>
                  <div>
                      <input
                        type='text'
                        id="booker.organization_other"
                        name="booker.organization_other"
                        maxLength="20"
                        {...register('booker.organization_other')}
                    placeholder="Organization/법인"
                      />
                      <div className='invalid-feedback'>{errors.booker?.organization_other?.message}</div>
                </div>
              </div>}
              <div className="form_item">
                <label htmlFor="memb_org" className="list_label"><span class="tit-desc-p">*</span>Job<span></span>직업</label>
                <div className={`${errors.booker?.job?.message ? 'is-invalid' : ''}`}>
                  <select id="booker.job" name="booker.job" {...register('booker.job')}>
                    <option value=''>please select</option>
                    {
                      jobs.map(job => (
                        <option value={job}>{job}</option>
                      ))
                    }
                  </select>
                  <div className='invalid-feedback'>{errors.booker?.job?.message}</div>
                </div>
              </div>
              
              <div className="form_item">
                <label htmlFor="booker.email" className="list_label"><span class="tit-desc-p">*</span>Email<span></span>이메일</label>
                <div className={`${errors.booker?.email?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='email'
                    id={`booker.email`}
                    name={`booker.email`}
                    {...register(`booker.email`,{
                      onChange: (e)=>{
                        e.target.value = e.target.value.replace(/[^0-9a-zA-Z-_.@]/g,'').toLowerCase();
                      }})}
                    placeholder='Enter your email'
                  />
                  <div className='invalid-feedback'>{errors.booker?.email?.message}</div>
                </div>
              </div>
              <div className="form_item mpMobile-phone">
                <label htmlFor="memb_ph" className="list_label"><span class="tit-desc-p">*</span>Mobile contact point<span></span>연락처</label>
                <div className='form_item__wrap'>
                  <div className={`${errors.booker?.countryCode?.message ? 'is-invalid' : ''}`}>
                    <select id="booker.countryCode" name="booker.countryCode" defaultValue={booker.countryCode} value={booker.countryCode} {...register('booker.countryCode')}>
                      <option value=''>please select</option>
                      {
                        mobileData.map(value => (
                          <option value={value}>{value}</option>
                        ))
                      }
                    </select>
                    <div className='invalid-feedback'>{errors.booker?.countryCode?.message}</div>
                  </div>
                  <div className={`${errors.booker?.mobile?.message ? 'is-invalid' : ''}`}>
                    <input
                      type='text'
                      id="booker.mobile"
                      name="booker.mobile"
                      {...register('booker.mobile',{
                        onChange: (e)=>{
                        e.target.value = e.target.value.replace(/[^0-9]/g,'');
                        // if(e.target.value.length > 0 && e.target.value.substring(0,1) === '0') {
                        //   e.target.value = e.target.value.substring(1,e.target.value.length)
                        // }
                      }})}
                      placeholder='Enter your mobile number'
                    />
                    <div className='invalid-feedback'>{errors.booker?.mobile?.message}</div>
                  </div>

                </div>
              </div>

            </div>
          </div>
          <span className="bar mb60"></span>
          <div className='buttons_center'>
            <Link to="/room/application-list" replace={true}><button type='button' className='btn-black mr10'><span className='spsans'>Cancel</span></button></Link>
            <button type='submit' className='btn-submit'><span className='spsans'>Submit</span></button>
          </div>
        </form>
      </section>)
    }
    </>
  );
}