import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import history from '@/helpers/history';

import Router from '@/routes'

import { setBasePath } from "@/actions/common";
import { refreshToken } from '@/actions/auth';
import { setIsLoading } from './actions/common';

function App() {
  const dispatch = useDispatch();

  history.navigate = useNavigate();
  history.location = useLocation();

  useEffect(() => {
    dispatch(refreshToken())
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setIsLoading(false));
    };
  });

  useEffect(() => {
    if(history.location.pathname.toLowerCase().indexOf('/viptour') > -1){
      dispatch(setBasePath('viptour'));
    } else if(history.location.pathname.toLowerCase().indexOf('/room') > -1){
      dispatch(setBasePath('room'));
    } else {
      dispatch(setBasePath(''));
    }
  }, [history.navigate]);

  return (
    <Router/>
  );
}

export default App;
