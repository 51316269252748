const createTimeSlots = (date, startHour, endHour) => {
  const slots = [];
  let currentTime = new Date(`2025-01-${String(date).padStart(2, '0')}T${String(startHour).padStart(2, '0')}:00:00`);
  const endTime = new Date(`2025-01-${String(date).padStart(2, '0')}T${String(endHour).padStart(2, '0')}:00:00`);
  
  while (currentTime < endTime) {
    const startTimeStr = currentTime.toTimeString().substring(0, 5);
    const endTime = new Date(currentTime.getTime() + 40 * 60000);
    const endTimeStr = endTime.toTimeString().substring(0, 5);
    
    slots.push({
      date: `2025-01-${String(date).padStart(2, '0')}`,
      time: `${startTimeStr} ~`,
      label: `${startTimeStr} ~ ${endTimeStr}`
    });
    
    currentTime = new Date(currentTime.getTime() + 40 * 60000);
  }
  return slots;
};

const tourTime = [
  // 1월 7일 (10am - 6pm)
  ...createTimeSlots(7, 10, 18),
  
  // 1월 8-9일 (9am - 6pm)
  ...createTimeSlots(8, 9, 18),
  ...createTimeSlots(9, 9, 18),
  
  // 1월 10일 (9am - 4pm)
  ...createTimeSlots(10, 9, 16)
];

export default tourTime;
