import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import ReactToPrint from 'react-to-print';
import { cancelReservation, tours, tour } from '@/actions/tour';
import { setIsLoading } from '@/actions/common';
import _ from 'lodash';
import { reservation } from '@/actions/tour';

export default function TourRerservationList() {
  const dispatch = useDispatch();
  
  const [currentItems, setCurrentItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [isReservationView, setIsReservationView] = useState(false);
  const [isReservationCancel, setIsReservationCancel] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const componentRef = useRef(null);

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(tours({
      page: currentPage,
    }))
    .then(res => {
      setTotalPageCount(res.totalPages);
      setTotalItemCount(res.totalDocs);
      setCurrentItems(res.docs);
      dispatch(setIsLoading(false));
    })
  }, [currentPage]);

  const onOpenItem = (item) => {
    // console.log(reservation_id)
    // dispatch(reservation(reservation_id))
    // .then((res) => {
      setSelectedItem(item);
      setIsReservationView(true);
    // })
  }

  const onCancelItem = (item) => {
    // dispatch(Reservation(Reservation_id))
    // .then((res) => {
    //   setSelectedItem(res);
    //   setIsReservationCancel(true);
    // })
    setSelectedItem(item);
    setIsReservationCancel(true);
  }

  const onCancelItemConfirm = () => {
    dispatch(cancelReservation(selectedItem.id))
    .then(res => {
      const index = _.findIndex(currentItems, {id: selectedItem.id});

      currentItems.splice(index, 1, {...currentItems[index], status: 'Canceled'});

      setIsReservationCancel(false);
    })
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  return (
    <>
    <div className="container_top ticket_top">
      <div className="top-info top-info_cn">
        <h2 className="top-title">Reservation list</h2>
      </div>
    </div>
          <div className='container_body-tit mb40'>
        <h3>VIP tour reservation</h3>
      </div>
      <section className="reservation-list">
        <table className="table_list2">
          <thead>
            <tr>
              <th scope="col" rowSpan="2" style={{width: '58px' }}>No</th>
              <th scope="col" colSpan="2">Tour request</th>
              <th scope='col' colSpan="3">VIP guests</th>
              <th scope="col" colSpan="2">Samsung host</th>
              <th scope="col" colSpan="2">VIP tour PIC</th>
              <th scope='col' rowSpan='2'>Detail</th>
              <th scope="col" rowSpan="2">Status</th>
              <th scope="col" rowSpan="2">Cancellation/<br/>Modification</th>
            </tr>
            <tr>
              <th scope='col' style={{whiteSpace:'nowrap'}}>Date & Time</th>
              <th scope='col' style={{whiteSpace:'nowrap'}}>Tour language</th>
              <th scope='col'>Name</th>
              <th scope='col'>Company</th>
              <th scope='col'>Number<br/>(Invitees) &<br/>Number<br/>(Employee)</th>
              <th scope='col'>Executive name</th>
              <th scope='col'>Executive position</th>
              <th scope='col'>Name</th>
              <th scope='col'>Mobile</th>
            </tr>
          </thead>
          <tbody>
            {currentItems && currentItems.map((item, index)=>(
              <tr key={item.id}>
              <td>{totalItemCount - (currentPage - 1) * 10 - index}</td>
              <td style={{textAlign:'center'}}>
                <Moment format='DD.MMM.YYYY'>{item.date}</Moment><br/>{item.time}
              </td>
              <td>{item.language}</td>
              <td style={{whiteSpace:'nowrap'}}>{item?.guests[0]?.name}</td>
              <td>{item?.guests[0]?.company}</td>
              <td style={{maxWidth:'80px'}}>{item.inviteNumber} / {item.inviteSamsungEmployeesNumber}</td>
              <td>{item.host.executiveName}</td>
              <td>{item.host.executivePosition}</td>
              <td style={{whiteSpace:'nowrap'}}>{item.pic.firstName} {item.pic.lastName}</td>
              <td style={{whiteSpace:'nowrap'}}>{item.pic.countryCode} {item.pic.mobile}</td>
              <td>
                {/* <button className="btn-view" data-toggle="modal" data-target="#modalInviteesView"><span className="spsans">View</span></button> */}
                {/* {item.status !== 'Canceled' && (<button className='btn-view' onClick={()=>onOpenItem(item)}><span className='spsans'>View</span></button>)} */}
                {item.status !== 'Canceled' && (<Link to={`/viptour/reservation/${item.id}`}><button className='btn-view'><span className='spsans'>View</span></button></Link>)}
              </td>
              <td className="nowrap">
                <span className={item.status === 'Approved' ? 'warning' : ''}>{item.status}</span>
              </td>
              <td style={{maxWidth:'135px'}}>
                <button className="btn-cancel-r mb5" onClick={()=>onCancelItem(item)} disabled={item.status !== 'In Progress'}><span className="spsans">Cancel</span></button>
                {
                  item.status === 'In Progress' ? (<Link to={`/viptour/reservation/${item.id}/modify`}><button className="btn-modify-r" disabled={item.status !== 'In Progress'}><span className="spsans">Modify</span></button></Link>) : (<button className="btn-modify-r" disabled={true}><span className="spsans">Modify</span></button>)
                }
              </td>
            </tr>
            ))}
            {
              (currentItems && currentItems.length === 0) && (<tr>
                <td colSpan='13' className='no_data'>
                  <p>No Data</p>
                </td>
              </tr>)
            }
            {/* <tr>
              <td>03</td>
              <td>22.08.11<br/>12:00~12:40</td>
              <td>English</td>
              <td>Sydney Kim</td>
              <td>+82 10-1234-5678</td>
              <td>kangnyun kim</td>
              <td>CEO</td>
              <td className='nowrap'>Joe Kim<br/>+82 10-2234-3567</td>
              <td>3</td>
              <td><button className="btn-view" data-toggle="modal" data-target="#modalInviteesView"><span className="spsans">View</span></button></td>
              <td className="nowrap"><strong>In Process</strong></td>
              <td style={{maxWidth:'130px'}}>
                <button className="btn-cancel-r mb5"><span className="spsans">Cancel</span></button>
                <button className="btn-modify-r"><span className="spsans">Modify</span></button>
              </td>
            </tr>
            <tr>
              <td>02</td>
              <td>2022.08.22<br/>12:00-12:40</td>
              <td>English</td>
              <td>Seongyeul Park</td>
              <td>Director</td>
              <td>Seongyeul Park</td>
              <td className="nowrap">+61 0402-661-931</td>
              <td className="nowrap">Kangnyun Kim<br/>+82-10-2668-2351</td>
              <td>2</td>
              <td><button className="btn-view"><span className="spsans">View</span></button></td>
              <td className="nowrap"><strong>Canceled</strong></td>
              <td style={{maxWidth:'130px'}}>-</td>
            </tr>
            <tr>
              <td>01</td>
              <td>2022.08.22<br/>12:00-12:40</td>
              <td>English</td>
              <td>Seongyeul Park</td>
              <td>Director</td>
              <td>Seongyeul Park</td>
              <td className="nowrap">+61 0402-661-931</td>
              <td className="nowrap">Kangnyun Kim<br/>+82-10-2668-2351</td>
              <td>3</td>
              <td><button className="btn-view"><span className="spsans">View</span></button></td>
              <td className="nowrap"><strong className="warning">Approved</strong></td>
              <td style={{maxWidth:'130px'}}>-</td>
            </tr> */}
          </tbody>
        </table>
        <div className='paging'>
          <ReactPaginate
            breakLabel='...'
            nextLabel={<span className='paging_end pl30'></span>}
            onPageChange={handlePageClick}
            forcePage={ currentPage - 1 }
            pageRangeDisplayed={3}
            pageCount={totalPageCount}
            previousLabel={<span className='paging_first pl30'></span>}
            renderOnZeroPageCount={null}
            activeLinkClassName='paging_num paging_current'
            pageLinkClassName='paging_num'
            breakLinkClassName='paging_num'
            previousLinkClassName='paging_num'
            nextLinkClassName='paging_num'
          />
        </div>
      </section>

      {/* vip guests view */}
      <div className="modal fade in" id="modalInviteesView"  style={{display: isReservationView ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog_lg modal-dialog-centered">
          <div className="modal-content">
          <ReactToPrint
            trigger={() => <button className='btn-print'></button>}
            content={() => componentRef.current}
          />
          <button className='btn-modalclose' onClick={()=>setIsReservationView(false)}></button>
          <div ref={componentRef}>
            <div className="modal-header">
                <h3 className="modal-title">Number of invited VIP guests</h3>
            </div>
            <div className="modal-body">
            {
                selectedItem &&
                (
              <ul className="modal_view clear">
                {
                  selectedItem.guests.map(guest=>(
                    <>
                      <li className="view-third">
                        <small>Name</small>
                        <span className="modal_value">{guest.name}</span>
                      </li>
                      <li className="view-third">
                        <small>Company</small>
                        <span className="modal_value">{guest.company}</span>
                      </li>
                      <li className="view-third">
                        <small>Position</small>
                        <span className="modal_value">{guest.position}</span>
                      </li>
                    </>
                  ))
                }
              </ul>
                )
              }
            </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={()=>setIsReservationView(false)}><span className="spsans">OK</span></button>
            </div>
          </div>
        </div>
    </div>
    <div className='modal fade in' id='modalCancel' style={{display: isReservationCancel ? 'block' : 'none'}}>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content modal-content-p0'>
          <div className='modal-body'>
            <p className="mb10">Are you sure you want to cancel this?</p>
            <p className='fw-md'>정말 취소하시겠습니까?</p>
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn-done sssb' onClick={()=>onCancelItemConfirm()}>Yes</button>
            &nbsp;
            <button type='button' className='btn-cancel mr10 sssb' onClick={()=>setIsReservationCancel(false)}>No</button>
          </div>
        </div>
      </div>
  </div>
    </>
  );
}
