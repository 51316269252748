import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import regionData from '@/data/regionData';
import mobileData from '@/data/mobileData';
import companyData from '@/data/companyData';
import history from '@/helpers/history';
import _ from 'lodash';

import * as Yup from 'yup';

import { existsMobile, me, updateAccount } from '@/actions/auth';

const MyPage = () => {
  const dispatch = useDispatch();
  const { basePath } = useSelector((state) => state.common);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
              .nullable()
              .transform((value) => (value === '' ? null : value)) // 빈 문자열을 null로 변환
              .min(8, 'Password must be at least 8 characters')
              // .max(20, 'must be at most 20 characters')
              .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                'Password must contain letters, numbers, and special characters'
              ),
    confirmPassword: Yup.string()
              .nullable()
              .transform((value) => (value === '' ? null : value))
              .when('password', {
                is: (value) => value !== null && value !== undefined && value !== '',
                then: (schema) => schema
                  .required('Confirm password is required')
                  .oneOf([Yup.ref('password')], 'Passwords must match')
              }),
    firstName: Yup.string().required('Required field'),
    lastName: Yup.string().required('Required field'),
    company: Yup.string().required('Required field'),
    division: Yup.string().required('Required field'),
    division_other: Yup.string().when("division", {
      is: 'Other',
      then: Yup.string().required("Required field")
    }),
    region: Yup.string().required('Required field'),
    organization: Yup.string().required('Required field'),
    organization_other: Yup.string().when("organization", {
      is: 'Other',
      then: Yup.string().required("Required field")
    }),
    countryCode: Yup.string().required('Required field'),
    mobile: Yup.string().required('Required field')
  });

  const formOptions = {
    resolver: yupResolver(validationSchema)
  };

  const { register, handleSubmit, setValue, reset, setError, formState, watch } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const countryCode = watch('countryCode');
  const mobile = watch('mobile');
  const isCheckMobile = watch('isCheckMobile');
  const [myPageData, setMyPageData] = useState(null);
  const [isDupMobile, setIsDupMobile] = useState(false);

  const company = watch('company');
  const region = watch('region');
  const division = watch('division');
  const organization = watch('organization');

  useEffect(() => {
    setValue('isCheckMobile', false, { shouldValidate: true })
  }, [countryCode, mobile, setValue])

  // useEffect(() => {
  //   if(organization){
  //     setValue('countryCode', region && organization ? regionData[region][organization] : '')
  //   }
  // }, [region, organization, setValue])

  useEffect(() => {
    dispatch(me())
      .then((res) => {
        setMyPageData(res);

        reset({
          userId: res.userId,
          emailDomain: res.emailDomain,
          firstName: res.firstName,
          lastName: res.lastName,
          company: res.company,
          division: res.division,
          division_other: res.division_other,
          region: res.region,
          organization: res.organization,
          organization_other: res.organization_other,
          countryCode: res.countryCode,
          mobile: res.mobile
        })
      })
  }, [dispatch, reset]);

  const onSubmit = (params) => {
    if(params.password){//패스워드 입력한 경우
      if(params.password.length < 6){
        setError('password', { message: 'Password must be at least 6 characters' });
        return;
      } else if (!params.confirmPassword) {
        setError('confirmPassword', { message: 'Required field' });
        return;
      } else if (params.password !== params.confirmPassword) {
        setError('confirmPassword', { message: 'Passwords must match' });
        return;
      }
    }

    if(!isCheckMobile && (myPageData.countryCode !== params.countryCode || myPageData.mobile !== params.mobile)){//휴대폰 번호입력이 된겨우
      setError('isCheckMobile', { message: 'Please check mobile' });
      return;
    }

    dispatch(updateAccount(myPageData.id, {
      firstName: params.firstName,
      lastName: params.lastName,
      password: params.password,
      confirmPassword: params.confirmPassword,
      company: params.company,
      division: params.division,
      division_other: params.division_other,
      region: params.region,
      organization: params.organization,
      organization_other: params.organization_other,
      countryCode: params.countryCode,
      mobile: params.mobile,
    }))
    .then((res) => {
      history.navigate(basePath ? `/${basePath}` : `/`, { replace: true })
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  const checkMobile = () => {
    if(!mobile){
      setError('mobile', { message: 'required field' });
      return;
    } else if( mobile.length > 20 || mobile.length < 6 ) {
      setError('mobile', { message: 'mobile must be between 3 and 20 characters long' });
      return;
    } else {
      setError('mobile', { message: '' });
    }

    dispatch(existsMobile(mobile))
    .then((res) => {
      if(res.isExistsMobile){
        setIsDupMobile(true)
      }else{
        setValue('isCheckMobile', true, { shouldValidate: true })
      }
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }
const [focusedInput, setFocusedInput] = useState(null);

  const handleFocus = (inputName) => {
    setFocusedInput(inputName);
  };

  const handleBlur = () => {
    setFocusedInput(null);
  };
  return (
    <>
      <div className='container_top ticket_top'>
        <div className='top-info top-info_cn'>
          <h2 className='top-title'><span className='spsans'>My page</span></h2>
        </div>
      </div>    
      <div className='container container_my'>
        <div className='container_body-tit'>
          <h3>My information</h3>
          <span className='tit-desc-box'><span className='tit-desc-box-p'>*</span> All fields are required</span>
        </div>
        <form className='form signup mypage' onSubmit={handleSubmit(onSubmit)}>
          <div className='form_inner'>
            <div className='form_item'>
              <label htmlFor='userId' className='list_label'><span className='tit-desc-p'>*</span> User ID<span></span>아이디</label>
              <div className='useId_wrap'>
                <input 
                  type='text'
                  id='userId'
                  name='userId'
                  {...register('userId')}
                  placeholder=''
                  maxLength='20'
                  disabled
                />
                <span>@</span>
                <select id='emailDomain' name='emailDomain' {...register('emailDomain')} disabled>
                  <option value='samsung.com'>samsung.com</option>
                  <option value='partner.samsung.com'>partner.samsung.com</option>
                  <option value="smartthings.com">smartthings.com</option>
                  <option value="sea.samsung.com">sea.samsung.com</option>
                  <option value="partner.sea.samsung.com">partner.sea.samsung.com</option>
                </select>
              </div>
            </div>
            <div className='register_pw'>
              <div className='form_item'>
                <label htmlFor='password' className={`list_label ${focusedInput === 'password' ? 'focused' : ''}`}><span className='tit-desc-p'>*</span> Password<span></span>비밀번호</label>
                <div className={`${errors.password?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='password'
                    id='password'
                    placeholder='Enter your password'
                    name='password'
                    onFocus={() => handleFocus('password')}
                    onBlur={handleBlur}
                    {...register('password',{
                      onChange: (e)=>{
                        e.target.value = e.target.value.replace(/[^A-Za-z0-9!@#$%&*^]/g,'');
                      }})}
                    onPaste={e=>e.preventDefault()}
                  />
                  <div className='invalid-feedback'>{errors.password?.message}</div>
                </div>
              </div>
              <div className='form_item'>
                <label htmlFor='confirmPassword' className={`list_label ${focusedInput === 'confirmPassword' ? 'focused' : ''}`}><span className='tit-desc-p'>*</span> Confirm password<span></span>비밀번호 확인</label>
                <div className={`${errors.confirmPassword?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='password'
                    id='confirmPassword'
                    placeholder='Confirm your password'
                    name='confirmPassword'
                    onFocus={() => handleFocus('confirmPassword')}
                    onBlur={handleBlur}
                    {...register('confirmPassword',{
                      onChange: (e)=>{
                        e.target.value = e.target.value.replace(/[^A-Za-z0-9!@#$%&*^]/g,'');
                      }})}
                    onPaste={e=>e.preventDefault()}
                  />
                  <div className='invalid-feedback'>{errors.confirmPassword?.message}</div>
                </div>
              </div>
            </div>
            <div className='form_item'>
              <label htmlFor='firstName' className={`list_label ${focusedInput === 'firstName' ? 'focused' : ''}`}><span className='tit-desc-p'>*</span> Name<span></span>이름</label>
              <div className='register_name'>
                <div className={`${errors.firstName?.message ? 'is-invalid' : ''}`}>
                    <input
                      type='text'
                      id='firstName'
                      name='firstName'
                      maxLength='20'
                      onFocus={() => handleFocus('firstName')}
                      onBlur={handleBlur}
                      {...register('firstName',{
                        onChange: (e)=>{
                          e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣0-9a-zA-Z _.-]/g,'');
                        }})}
                      placeholder='First name'
                    />
                    <div className='invalid-feedback'>{errors.firstName?.message}</div>
                </div>
                <div className={`${errors.lastName?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='text'
                    id='lastName'
                    name='lastName'
                    onFocus={() => handleFocus('firstName')}
                    onBlur={handleBlur}
                    maxLength='20'
                    {...register('lastName',{
                      onChange: (e)=>{
                        e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣0-9a-zA-Z _.-]/g,'');
                      }})}
                    placeholder='Last name'
                  />
                  <div className='invalid-feedback'>{errors.lastName?.message}</div>
                </div>
              </div>
            </div>
            <div className='register_corp mb60'>
              <div className='form_item mb30'>
                <label htmlFor='company' className={`list_label ${focusedInput === 'company' ? 'focused' : ''}`}><span className='tit-desc-p'>*</span> Company/Division <span></span> 회사/사업부</label>
                <div className='form_item__wrap other__wrap'>
                  <div className={`${errors.company?.message ? 'is-invalid' : ''}`}>
                    <select id="company" name="company" onFocus={() => handleFocus('company')}
                  onBlur={handleBlur}{...register('company')}>
                      <option value=''>Please select</option>
                      {
                        _.filter(Object.keys(companyData),key => key !== 'Third party(협력사)').map(key => (
                          <option value={key}>{key}</option>
                        ))
                      }
                    </select>
                    <div className='invalid-feedback'>{errors.company?.message}</div>
                  </div>
                  <div className={`${errors.division?.message ? 'is-invalid ml20' : 'ml20'}`}>
                    <select id="division" name="division" onFocus={() => handleFocus('company')}
                      onBlur={handleBlur}{...register('division',{
                        onChange: (e)=>{
                          setValue('division_other', '')
                        }
                      })}>
                      <option value=''>Please select</option>
                      {
                        company && companyData[company].map(name => (
                          <option value={name}>{name}</option>
                        ))
                      }
                    </select>
                    <div className='invalid-feedback'>{errors.division?.message}</div>
                  </div>
                  {division === 'Other' && <div className={`other__input ${errors.division_other?.message ? 'is-invalid' : ''}`}>
                    <input
                      type='text'
                      id="division_other"
                      name="division_other"
                      maxLength="20"
                      onFocus={() => handleFocus('division_other')}
                      onBlur={handleBlur}
                      {...register('division_other')}
                      placeholder="Division/사업부"
                    />
                    <div className='invalid-feedback'>{errors.division_other?.message}</div>
                  </div>}
                </div>
              </div>
              <div className='form_item'>
                <label htmlFor='region' className={`list_label ${focusedInput === 'region' ? 'focused' : ''}`}><span className='tit-desc-p'>*</span> Region/Organization<span></span>지역/법인</label>
                <div className='form_item__wrap other__wrap'>
                  <div className={`${errors.region?.message ? 'is-invalid' : ''}`}>
                    <select id='region' name='region' onFocus={() => handleFocus('region')}
                      onBlur={handleBlur} {...register('region')}>
                      <option value=''>Please select</option>
                      {
                        Object.keys(regionData).map(key => (
                          <option value={key} key={key}>{key}</option>
                        ))
                      }
                    </select>
                    <div className='invalid-feedback'>{errors.region?.message}</div>
                  </div>
                  <div className={`${errors.organization?.message ? 'is-invalid ml20' : 'ml20'}`}>
                  <select id='organization' name='organization' onFocus={() => handleFocus('region')}
                    onBlur={handleBlur}{...register('organization',{
                      onChange: (e)=>{
                        setValue('organization_other', '')
                        setValue('countryCode', regionData[region][e.target.value])
                      }
                    })}>
                    <option value=''>Please select</option>
                    {
                      region && Object.keys(regionData[region]).map(key => (
                        <option value={key} key={key}>{key}</option>
                      ))
                    }
                  </select>
                  <div className='invalid-feedback'>{errors.organization?.message}</div>
                  {/* <div className='other__input'>
                    <input
                      type='text'
                      id="organization"
                      name="organization"
                      maxLength="20"
                      onFocus={() => handleFocus('organization')}
                      onBlur={handleBlur}
                      {...register('organization')}
                      placeholder=''
                    />
                    <div className='invalid-feedback'>{errors.organization?.message}</div>
                  </div> */}
                </div>
                {/* <div className={`${errors.organization?.message ? 'is-invalid ml20' : 'ml20'}`}>
                  <input
                    type='text'
                    id="organization"
                    name="organization"
                    maxLength="20"
                    onFocus={() => handleFocus('organization')}
                    onBlur={handleBlur}
                    {...register('organization')}
                    placeholder='Organization'
                  />
                  <div className='invalid-feedback'>{errors.organization?.message}</div>
                </div> */}
                {organization === 'Other' && <div className={`other__input ${errors.organization_other?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='text'
                    id="organization_other"
                    name="organization_other"
                    maxLength="20"
                    {...register('organization_other')}
                    placeholder="Organization/법인"
                  />
                  <div className='invalid-feedback'>{errors.organization_other?.message}</div>
                  <div  className='invalid-feedback-ex'>
                    <span>Ex)</span> NA Office, SEA, SEG, SEI, SEIL
                  </div>
                </div>}
                </div>
              </div>
            </div>
            <div className='form_item mb60'>
              <label htmlFor='countryCode' className={`list_label ${focusedInput === 'mobile' ? 'focused' : ''}`}><span className='tit-desc-p'>*</span> Mobile contact point<span></span>연락처</label>
              <div className='form_item__wrap'>
                <div className={`${errors.countryCode?.message ? 'is-invalid' : ''}`}>
                  <select id='countryCode' name='countryCode' onFocus={() => handleFocus('mobile')}
                  onBlur={handleBlur}{...register('countryCode')} disabled={isCheckMobile}>
                    <option value=''>Please select</option>
                    {
                      mobileData.map(value => (
                        <option value={value} key={value}>{value}</option>
                      ))
                    }
                  </select>
                  <div className='invalid-feedback'>{errors.countryCode?.message}</div>
                </div>
                <div className={`${errors.mobile?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='text'
                    id='mobile'
                    name='mobile'
                    onFocus={() => handleFocus('mobile')}
                    placeholder='Enter your mobile number'
                  onBlur={handleBlur}
                    {...register('mobile',{
                      onChange: (e)=>{
                      e.target.value = e.target.value.replace(/[^0-9]/g,'');
                    }})}
                    disabled={isCheckMobile}
                  />
                  <div className='invalid-feedback'>{errors.mobile?.message}</div>
                </div>
                <div className={`${errors.isCheckMobile?.message ? 'is-invalid' : ''}`}>
                  {
                    isCheckMobile ?
                      (<button className='btn-success' type='button'><span className='spsans'>Success</span></button>) :
                      (
                        <div className='chk-btn'>
                          <button type='button' className='btn-check' onClick={()=>checkMobile()} ><span className='spsans'>Check</span></button>
                          <input type='checkbox' id='isCheckMobile' name='isCheckMobile' {...register('isCheckMobile')} style={{display: 'none'}}/>
                        </div>
                      )
                  }
                  <div className='invalid-feedback'>{errors.isCheckMobile?.message}</div>
                </div>
              </div>
            </div>
          </div>
        <span className='bar mb60'></span>
        <div className='buttons_center'>
          {/* <Link to='/' className='btn-cancel mr10'><span className='spsans'>Cancel</span></Link> */}
          <button type='submit' className='btn-submit' disabled={isSubmitting}><span className='spsans'>Save</span></button>
        </div>
        </form>
      </div>

      <div className='modal fade in' id='modalEmailDbchk' style={{display: isDupMobile ? 'block' : 'none'}}>
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content modal-content-p0'>
              <div className='modal-body'>
                <strong>This phone number is already in use.</strong>
                <p className='fw-md'>등록된 휴대폰번호 입니다.</p>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn-send ssone' onClick={()=>setIsDupMobile(false)}>Done</button>
              </div>
            </div>
          </div>
      </div>
    </>
  );
}

export default MyPage;