import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import moment from 'moment';
import _ from 'lodash';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import resetIcon from '@/assets/images/icon_refresh.png';
import "react-datepicker/dist/react-datepicker.css";

import { allReservations, approveReservation, declineReservation, sendMailReservationStatus, deleteManyReservation, downloadExcel } from '@/actions/room';

export default function AdminMember() {
  const dispatch = useDispatch();
  const [checkList, setCheckList] = useState([]);
  const [idList, setIdList] = useState([]);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageLimit, setPageLimit] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  
  const [isReservationRefusalModal, setIsReservationRefusalModal] = useState(false);
  const [isReservationApprovalModal, setIsReservationApprovalModal] = useState(false);
  const [isSendMailReservationInfoModal, setIsSendMailReservationInfoModal] = useState(false);
  const [isReservationDeleteModal, setIsReservationDeleteModal] = useState(false);

  const [userId, setUserId] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [status, setStatus] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchReset, setSearchReset] = useState(false);

  const { basePath } = useSelector((state) => state.common);

  useEffect(() => {
    getReservations();
  }, [currentPage, pageLimit, createdAt, status]);

  useEffect(() => {
    if(searchReset){
      handleSearch()
    }
  }, [searchReset]);

  const getReservations = () => {
    if(searchReset){
      setSearchReset(false);
    }

    dispatch(allReservations({
      page: currentPage,
      limit: pageLimit,
      userId,
      createdAt: createdAt ? moment(createdAt).format('YYYY-MM-DD') : '',
      status,
      text: searchText
    }))
    .then((res) => {
      setIdList(res.docs.map(item=>item.id))
      setTotalPageCount(res.totalPages);
      setTotalItemCount(res.totalDocs);
      setCurrentItems(res.docs);
      setCheckList([]);
    })
  }

  const handleSearch = () => {
    setCurrentPage(1);
    getReservations();
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  const onChangeAll = (e) => {
    setCheckList(e.target.checked ? idList : [])
  }

  const onChangeEach = (e, id) => {
    if (e.target.checked) {
      setCheckList([...checkList, id]);
    } else {
      setCheckList(checkList.filter((checkedId) => checkedId !== id));
    }
  }

  const onChangePageLimit = (e) => {
    const value = e.target.value || 20;
    setPageLimit(value);
    setCurrentPage(1);
  }

  const onChangeUserId = (e) => {
    const value = e.target.value || '';
    setUserId(value);
  }

  const onChangeCreatedAt = (value) => {
    setCreatedAt(value);
  }

  const onChangeStatus = (e) => {
    const value = e.target.value || '';
    setStatus(value);
  }

  const onChangeSearchText = (e) => {
    const value = e.target.value || '';
    setSearchText(value);
  }

  const onRefusalItemConfirm = () => {
    dispatch(declineReservation({
      ids: checkList
    }))
    .then((res) => {
      checkList.forEach(id=>{
        const index = _.findIndex(currentItems, {id});
        currentItems.splice(index, 1, {...currentItems[index], status: 'Declined'});
      })
      
      setCheckList([]);
      setIsReservationRefusalModal(false);
      toast.success('Reservation was refused.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
      });
    })
  }

  const onApprovalItemConfirm = () => {
    dispatch(approveReservation({
      ids: checkList
    }))
    .then((res) => {
      checkList.forEach(id=>{
        const index = _.findIndex(currentItems, {id});
        currentItems.splice(index, 1, {...currentItems[index], status: 'Approved'});
      })
      setCheckList([]);
      setIsReservationApprovalModal(false);
      toast.success('Reservation was approved.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
      });
    })
  }

  const onSendMailReservationStatusConfirm = () => {
    dispatch(sendMailReservationStatus({
      ids: checkList
    }))
    .then((res) => {
      setCheckList([]);
      setIsSendMailReservationInfoModal(false);
      toast.success('You have successfully sent an email.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
      });
    })
  }

  const onDeleteItemConfirm = () => {
    dispatch(deleteManyReservation({
      ids: checkList
    }))
    .then((res) => {
      setIsReservationDeleteModal(false);
      getReservations();
      toast.success('Delete completed.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
      });
    })
  }

  const displayEmail = winHtml => {
    const w= 800; 
    const h= 600;

    const winUrl = URL.createObjectURL(
      new Blob([winHtml], { type: "text/html" })
    );

    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    const newWindow = window.open(winUrl, '이메일 발송내용', 
      `
      scrollbars=yes,
      width=${w}, 
      height=${h}, 
      top=${top}, 
      left=${left}
      `
    )
  }

  return (
    <div className='container_adm'>
      <div className='search_wrap search_wrap2 mb40'>
        <div className='input_search-wrap'>
          <input type='text' className='input_search' placeholder='ID' onChange={onChangeUserId} value={userId}/>
          <button type="button" onClick={()=>getReservations()}></button>
        </div>
        <div className='search_wrap-inner'>
          {/* <input type='date' placeholder='YYYY-MM-DD' title="YYYY-MM-DD" min="2022-09-01" onChange={onChangeCreatedAt} style={{imeMode: 'disabled'}}/> */}
          <DatePicker
            minDate={new Date("2022-09-01 00:00:00")}
            selected={createdAt}
            dateFormat="yyyy-MM-dd"
            placeholderText="Date"
            isClearable
            onChange={onChangeCreatedAt}
            className='select_adm'
          />
          <select name='' id='' className='select_adm'  onChange={onChangeStatus}>
            <option value='' selected={status === ''}>Status</option>
            <option value='In Progress' selected={status === 'In Progress'}>In Progress</option>
            <option value='Approved' selected={status === 'Approved'}>Approved</option>
            <option value='Canceled' selected={status === 'Canceled'}>Canceled</option>
            <option value='Declined' selected={status === 'Declined'}>Declined</option>
          </select>
          <div className='input_search-wrap'>
            <input type='text' className='input_search' placeholder='Host Name or Email'  onChange={onChangeSearchText}value={searchText}/>
            <button type="button" onClick={()=>getReservations()}></button>
          </div>
          <button type="button" className='btn-reset' onClick={()=>{
            setUserId('');
            setCreatedAt('');
            setStatus('');
            setSearchText('');
            setSearchReset(true);
          }}><img src={resetIcon} alt='reset' /></button>
        </div>
      </div>
      <div className='adm_title-wrap mb20'>
        <div>
          <h2>Business lounge reservation list</h2>
          <select className='select_adm w100x' onChange={onChangePageLimit}>
            <option value='20' selected={pageLimit === 20}>20</option>
            <option value='50' selected={pageLimit === 30}>50</option>
            <option value='100' selected={pageLimit === 40}>100</option>
          </select>
        </div>
        <button className='btn-excel' onClick={()=>dispatch(downloadExcel())}>Export to Excel</button>
      </div>
      {
        currentItems && (
          <>
          <table className='table_adm mb40'>
          <thead>
            <tr>
              <th scope='col'>NO</th>
              <th scope='col'><input type='checkbox' className='chk_black' onChange={onChangeAll} checked={checkList.length === idList.length} /></th>
              <th scope='col'>ID</th>
              <th scope='col'>Room name</th>
              <th scope='col'>Host name</th>
              <th scope='col'>Meeting title</th>
              <th scope='col'>Date</th>
              <th scope='col'>Region</th>
              <th scope='col'>Detail</th>
              <th scope='col'>Status</th>
              <th scope='col'>Email</th>
              <th scope='col'>Cancellation/<br/>Modification</th>
            </tr>
          </thead>
          <tbody>
          {
            currentItems.map((item, index)=>(
              <tr key={item.id}>
                <td>{totalItemCount - (currentPage - 1) * pageLimit - index}</td>
                <td><input type='checkbox' className='chk_black' onChange={(e) => onChangeEach(e, item.id)} checked={checkList.includes(item.id)}/></td>
                <td>{item.userId}</td>
                <td>{item.room}</td>
                <td>{item?.host?.firstName} {item?.host?.lastName}</td>
                <td>{item.title}</td>
                <td><Moment format='DD.MMM.YYYY'>{item.dateLabel}</Moment><br/>{item.startTimeLabel}~{item.endTimeLabel}</td>
                <td>{item?.host?.region}</td>
                <td>
                  <Link to={basePath ? `/${basePath}/admin/room/${item.id}` : `/admin/room/${item.id}`}>
                    <button className='btn-detail'><span className='spsans'>View</span></button>
                  </Link>
                </td>
                <td>
                  {/* <select className='select_status' disabled={item.status === 'Canceled'}>
                    <option value='In Progress' selected={item.status === 'In Progress'}>In Progress</option>
                    <option value='Canceled' selected={item.status === 'Canceled'}>Canceled</option>
                    <option value='Decline' selected={item.status === 'Canceled'}>Canceled</option>
                    <option value='Approved' selected={item.status === 'Approved'}>Approved</option>
                  </select> */}
                  {/* {
                    item.status === 'In Progress' ? (<label htmlFor={`status_${item.id}`} style={{lineHeight:'20px'}}><input type='checkbox' id={`status_${item.id}`} onChange={(e) => onChangeEach(e, item.id)} checked={checkList.includes(item.id)} />&nbsp;{item.status}</label>) : (<>{item.status}</>)
                  } */}
                  {item.status}
                </td>
                <td>
                    {
                      item?.emailHist?.length > 0 ? <>
                      <ul>
                        <li><a href='#' onClick={()=>displayEmail(item.emailHist[item.emailHist.length-1].html)}><Moment format='DD.MMM.YYYY HH:mm'>{item.emailHist[item.emailHist.length-1].created}</Moment></a></li>
                      </ul>
                      </> : null
                    }
                  </td>
                <td>
                  {item.status !== 'Canceled' ? (
                    <Link to={basePath ? `/${basePath}/admin/room/${item.id}/modify` : `/admin/room/${item.id}/modify`}>
                      <button className='btn-modify'><span className='spsans'>Modify</span></button>
                    </Link>
                  ) : (
                    <button className='btn-modify' disabled={item.status === 'Canceled'}><span className='spsans'>Modify</span></button>
                  )}
                </td>
              </tr>
            ))
          }        
          {
          (currentItems && currentItems.length === 0) && (<tr>
            <td colSpan='11' className='no_data'>
              <p>No Data</p>
            </td>
          </tr>)
          }
        </tbody>
      </table>
      <div className='paging mb60'>
            <ReactPaginate
              breakLabel="..."
              nextLabel={<span className='paging_end pl30'></span>}
              onPageChange={handlePageClick}
              forcePage={ currentPage - 1 }
              pageRangeDisplayed={3}
              pageCount={totalPageCount}
              previousLabel={<span className='paging_first pr30'></span>}
              renderOnZeroPageCount={null}
              activeLinkClassName="paging_num paging_current"
              pageLinkClassName="paging_num"
              breakLinkClassName="paging_num"
              previousLinkClassName="paging_num"
              nextLinkClassName="paging_num"
            />
          </div>
          <div className='buttons_center'>
            <button className='btn-cancel mr10 bg_gray' type='button' onClick={()=>setIsReservationDeleteModal(true)} disabled={checkList.length === 0}><span className='spsans'>Delete Selection</span></button>
            <button className='btn-submit mr10 bg_red' type='button' onClick={()=>setIsReservationRefusalModal(true)} disabled={checkList.length === 0}><span className='spsans'>Refusal</span></button>
            <button className='btn-submit mr10 bg_blue' type='button' onClick={()=>setIsReservationApprovalModal(true)} disabled={checkList.length === 0}><span className='spsans'>Approval</span></button>
            <Link to={basePath ? `/${basePath}/admin/room/reservation/timetable` : "/admin/room/reservation/timetable"}><button className='btn-submit mr10 bg_black' type='button'><span className='spsans'>Add Reservation</span></button></Link>
            <button className='btn-mail' type='button' disabled={checkList.length === 0} onClick={()=>setIsSendMailReservationInfoModal(true)}><span className='spsans'>Send email</span></button>
          </div>
          </>
        )
      }
       <div className="modal fade in" id="modalCancel" style={{display: isReservationRefusalModal ? 'block' : 'none'}}>
          <div className="modal-dialog modal-dialog_lg modal-dialog-centered">
            <div className="modal-content modal-content-p0">
              <div className="modal-body">
                <strong>Refuse this reservation?</strong>
                <div className=' mb30'></div>
                <table className="table_adm adm_rsv_table mb60">
                    <thead>
                      <tr>
                        <th scope="col">ID</th>
                        <th scope='col'>Room Name</th>
                        <th scope='col'>Date & Time</th>
                        <th scope='col'>Host Name</th>
                        <th scope='col'>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems && _.filter(currentItems, r=>checkList.includes(r.id)).map((item, index)=>( 
                        <>
                        <tr key={item.id}>
                          <td>{item.userId}</td>
                          <td>{item.room}</td>
                          <td><Moment format='DD.MMM.YYYY'>{item.dateLabel}</Moment><br/>{item.startTimeLabel}~{item.endTimeLabel}</td>
                          <td>{item?.host?.firstName} {item?.host?.lastName}</td>
                          <td>
                            <span className={`${item.status === 'Approved' ? 'warning' : ''}`}>{item.status}</span>
                          </td>
                        </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn-done ssans" onClick={()=>onRefusalItemConfirm()}>Yes</button>
                <button type="button" className="btn-cancel mr10 ssans" onClick={()=>setIsReservationRefusalModal(false)}>No</button>
              </div>
            </div>
          </div>
      </div>
      <div className="modal fade in" id="modalCancel" style={{display: isReservationApprovalModal ? 'block' : 'none'}}>
          <div className="modal-dialog modal-dialog_lg modal-dialog-centered">
            <div className="modal-content modal-content-p0">
              <div className="modal-body">
                <strong>Approve this reservation?</strong>
                <div className=' mb30'></div>
                <table className="table_adm adm_rsv_table mb60">
                    <thead>
                      <tr>
                        <th scope="col">ID</th>
                        <th scope='col'>Room Name</th>
                        <th scope='col'>Date & Time</th>
                        <th scope='col'>Host Name</th>
                        <th scope='col'>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems && _.filter(currentItems, r=>checkList.includes(r.id)).map((item, index)=>( 
                        <>
                        <tr key={item.id}>
                          <td>{item.userId}</td>
                          <td>{item.room}</td>
                          <td><Moment format='DD.MMM.YYYY'>{item.dateLabel}</Moment><br/>{item.startTimeLabel}~{item.endTimeLabel}</td>
                          <td>{item?.host?.firstName} {item?.host?.lastName}</td>
                          <td>
                            <span className={`${item.status === 'Approved' ? 'warning' : ''}`}>{item.status}</span>
                          </td>
                        </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn-done ssans" onClick={()=>onApprovalItemConfirm()}>Yes</button>
                <button type="button" className="btn-cancel mr10 ssans" onClick={()=>setIsReservationApprovalModal(false)}>No</button>
              </div>
            </div>
          </div>
      </div>
      <div className="modal fade in" id="modalCancel" style={{display: isSendMailReservationInfoModal ? 'block' : 'none'}}>
          <div className="modal-dialog modal-dialog_lg modal-dialog-centered">
            <div className="modal-content modal-content-p0">
              <div className="modal-body">
                <strong>Would you like to send an email?</strong>
                <div className=' mb30'></div>
                <table className="table_adm adm_rsv_table mb60">
                    <thead>
                      <tr>
                        <th scope="col">ID</th>
                        <th scope='col'>Room Name</th>
                        <th scope='col'>Date & Time</th>
                        <th scope='col'>Host Name</th>
                        <th scope='col'>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems && _.filter(currentItems, r=>checkList.includes(r.id)).map((item, index)=>( 
                        <>
                        <tr key={item.id}>
                          <td>{item.userId}</td>
                          <td>{item.room}</td>
                          <td><Moment format='DD.MMM.YYYY'>{item.dateLabel}</Moment><br/>{item.startTimeLabel}~{item.endTimeLabel}</td>
                          <td>{item?.host?.firstName} {item?.host?.lastName}</td>
                          <td>
                            <span className={`${item.status === 'Approved' ? 'warning' : ''}`}>{item.status}</span>
                          </td>
                        </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn-done ssans" onClick={()=>onSendMailReservationStatusConfirm()}>Yes</button>
                <button type="button" className="btn-cancel mr10 ssans" onClick={()=>setIsSendMailReservationInfoModal(false)}>No</button>
              </div>
            </div>
          </div>
      </div>
      <div className="modal fade in" id="modalCancel" style={{display: isReservationDeleteModal ? 'block' : 'none'}}>
          <div className="modal-dialog modal-dialog_lg modal-dialog-centered">
            <div className="modal-content modal-content-p0">
              <div className="modal-body">
                <strong>Are you sure you want to delete??</strong>
                <div className=' mb30'></div>
                <table className="table_adm adm_rsv_table mb60">
                    <thead>
                      <tr>
                        <th scope="col">ID</th>
                        <th scope='col'>Room Name</th>
                        <th scope='col'>Date & Time</th>
                        <th scope='col'>Host Name</th>
                        <th scope='col'>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems && _.filter(currentItems, r=>checkList.includes(r.id)).map((item, index)=>( 
                        <>
                        <tr key={item.id}>
                          <td>{item.userId}</td>
                          <td>{item.room}</td>
                          <td><Moment format='DD.MMM.YYYY'>{item.dateLabel}</Moment><br/>{item.startTimeLabel}~{item.endTimeLabel}</td>
                          <td>{item?.host?.firstName} {item?.host?.lastName}</td>
                          <td>
                            <span className={`${item.status === 'Approved' ? 'warning' : ''}`}>{item.status}</span>
                          </td>
                        </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn-done ssans" onClick={()=>onDeleteItemConfirm()}>Yes</button>
                <button type="button" className="btn-cancel mr10 ssans" onClick={()=>setIsReservationDeleteModal(false)}>No</button>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}
