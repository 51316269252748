import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import { reservations, reservation, cancelReservation, deleteReservation } from '@/actions/room';
import { setIsLoading } from '../../actions/common';
import Skeleton from 'react-loading-skeleton'

import _ from 'lodash';

export default function RoomApplicationList() {
  const dispatch = useDispatch();
  const [currentItems, setCurrentItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [isReservationCancelModal, setIsReservationCancelModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(reservations({
      page: currentPage,
    }))
    .then((res) => {
      setTotalPageCount(res.totalPages);
      setTotalItemCount(res.totalDocs);
      setCurrentItems(res.docs);
      dispatch(setIsLoading(false));
    })
  }, [currentPage]);

  const onCancelItem = (reservationId) => {
    // dispatch(reservation(reservation_id))
    // .then((res) => {
    //   setSelectedItem(res);
    //   setIsReservationCancelModal(true);
    // })
    setSelectedItem({id: reservationId});
    setIsReservationCancelModal(true);
  }

  const onCancelItemConfirm = () => {
    dispatch(cancelReservation(selectedItem.id))
    .then((res) => {
      const index = _.findIndex(currentItems, {id: selectedItem.id});

      currentItems.splice(index, 1, {...currentItems[index], status: 'Canceled'});

      setIsReservationCancelModal(false);
    })
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  return (
    <>
      <div className="container_top ticket_top">
        <div className="top-info top-info_cn">
          <h2 className="top-title">Application list</h2>
        </div>
      </div>
      <div className='container_body-tit mb40'>
        <h3>Business lounge reservation</h3>
      </div>
      <section className="application-list">
        {currentItems && (
        <table className="table_list">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Room name</th>
              <th scope="col">Status</th>
              <th scope="col">Date</th>
              <th scope="col">Meeting title</th>
              <th scope="col">Host name</th>
              <th scope="col">Region</th>
              <th scope="col">Detail</th>
              <th scope="col" style={{width:'160px'}}>Cancellation/<br/>Modification</th>
            </tr>
            </thead>
            <tbody>
            {
            currentItems && currentItems.map((item, index)=>(
            <tr key={index}>
                <td>{totalItemCount - (currentPage - 1) * 10 - index}</td>
                <td>{item.room}</td>
                <td>{item.status}</td>
                <td>
                  <Moment format='DD.MMM.YYYY'>{item.dateLabel}</Moment>
                  <br/>{item.startTimeLabel}~{item.endTimeLabel}</td>
                <td>{item.title}</td>
                <td>{item.hostFullName}</td>
                <td>{item.region}</td>
                <td>
                  { (item.status === 'In Progress' || item.status === 'Approved') && (<Link className="btn-view" to={`/room/reservation/${item.id}`}><span className="spsans">View</span></Link>) }
                </td>
                <td>
                  {(item.status === 'In Progress' || item.status === 'Approved') ? (<button className="btn-cancel-r mb5" onClick={()=>onCancelItem(item.id)}><span className="spsans">Cancel</span></button>) : (<button className="btn-cancel-r mb5" onClick={()=>onCancelItem(item.id)} disabled={item.status !== 'In Progress'}><span className="spsans">Cancel</span></button>)}
                  {
                    item.status === 'In Progress' ? (<Link to={`/room/reservation/${item.id}/modify`}><button className="btn-modify" disabled={item.status === 'Canceled'}><span className="spsans">Modify</span></button></Link>) : (<button className="btn-modify" disabled={item.status !== 'In Progress'}><span className="spsans">Modify</span></button>)
                  }
                </td>
              </tr>))
            }
            {
              (currentItems.length === 0) && (<tr>
                <td colSpan="9" className="no_data">
                  <p>No Data</p>
                </td>
              </tr>)
            }
            </tbody>
        </table>
        )}
        <div className="paging">
          <ReactPaginate
            breakLabel="..."
            nextLabel={<span className='paging_end pl30'></span>}
            onPageChange={handlePageClick}
            forcePage={ currentPage - 1 }
            pageRangeDisplayed={3}
            pageCount={totalPageCount}
            previousLabel={<span className='paging_first pl30'></span>}
            renderOnZeroPageCount={null}
            activeLinkClassName="paging_num paging_current"
            pageLinkClassName="paging_num"
            breakLinkClassName="paging_num"
            previousLinkClassName="paging_num"
            nextLinkClassName="paging_num"
          />
        </div>
      </section>

      <div className="modal fade in" id="modalCancel" style={{display: isReservationCancelModal ? 'block' : 'none'}}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content modal-content-p0">
              <div className="modal-body">
                <p className='mb10'>Are you sure you want to cancel this?</p>
                <p className="fw-md">정말 취소하시겠습니까?</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn-done ssans" onClick={()=>onCancelItemConfirm()}>Yes</button>
                &nbsp;
                <button type="button" className="btn-cancel mr10 ssans" onClick={()=>setIsReservationCancelModal(false)}>No</button>
              </div>
            </div>
          </div>
      </div>
    </>
  );
}