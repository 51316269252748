import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import regionData from '@/data/regionData';
import mobileData from '@/data/mobileData';
import companyData from '@/data/companyData';
import history from '@/helpers/history';
import iconIFAlogo from '@assets/images/ifa_LOGO_POP.png';
import _ from 'lodash';

import * as Yup from 'yup';

import { signup, existsEmail, existsMobile } from '@/actions/auth';

export default function Signup() {
  const dispatch = useDispatch();
  const { basePath } = useSelector((state) => state.common);

  const validationSchema = Yup.object().shape({
    userId: Yup.string().required('Required field'),
    isCheckUserId: Yup.bool().required('Required field').oneOf([true], 'Please click the ‘Check’ button'),
    password: Yup.string()
              .required('Required field')
              .min(8, 'Password must be at least 8 characters')
              // .max(20, 'must be at most 20 characters')
              .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                'Password must contain letters, numbers, and special characters'
              ),
    confirmPassword: Yup.string()
              .required('Required field')
              .oneOf([Yup.ref('password')], 'Passwords must match'),
    firstName: Yup.string().required('Required field'),
    lastName: Yup.string().required('Required field'),
    company: Yup.string().required('Required field'),
    division: Yup.string().required('Required field'),
    division_other: Yup.string().when("division", {
      is: 'Other',
      then: Yup.string().required("Required field")
    }),
    region: Yup.string().required('Required field'),
    organization: Yup.string().required('Required field'),
    organization_other: Yup.string().when("organization", {
      is: 'Other',
      then: Yup.string().required("Required field")
    }),
    countryCode: Yup.string().required('Required field'),
    mobile: Yup.string().required('Required field'),
    email: Yup.string().email('Email is Invalid').required('가입시 입력해주세요. 최종 배포시 삭제됩니다.'),
    isCheckMobile: Yup.bool().required('Required field').oneOf([true], 'Please click the ‘Check’ button'),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema)
  };

  const { register, handleSubmit, setValue, setError, formState, watch } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const [isDupUserId, setIsDupUserId] = useState(false);
  const [isDupMobile, setIsDupMobile] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isTicketNotice, setIsTicketNotice] = useState(false);
  const [ticketNoticeLang, setTicketNoticeLang] = useState('kr');

  const userId = watch('userId');
  const emailDomain = watch('emailDomain');
  const countryCode = watch('countryCode');
  const mobile = watch('mobile');
  const isCheckUserId = watch('isCheckUserId');
  const isCheckMobile = watch('isCheckMobile');
  const company = watch('company');
  const division = watch('division');
  const region = watch('region');
  const organization = watch('organization');

  // useEffect(() => {
  //   if(organization){
  //     setValue('countryCode', regionData[region][organization])
  //   }
  // }, [organization])

  useEffect(() => {
    setTimeout(() => {
      setIsTicketNotice(basePath != 'viptour' || basePath == '');
    }, 300);
    
  }, [basePath])

  useEffect(() => {
    setValue('isCheckUserId', false, { shouldValidate: true })
  }, [userId, emailDomain])

  useEffect(() => {
    setValue('isCheckMobile', false, { shouldValidate: true })
  }, [countryCode, mobile])

  const onSubmit = (params) => {
    setIsSubmit(true)
    dispatch(signup({
      userId: params.userId,
      emailDomain: params.emailDomain,
      firstName: params.firstName,
      lastName: params.lastName,
      // email: `${params.userId}@${params.emailDomain}`,
      email: params.email,
      password: params.password,
      company: params.company,
      division: params.division,
      division_other: params.division_other,
      confirmPassword: params.confirmPassword,
      region: params.region,
      organization: params.organization,
      organization_other: params.organization_other,
      countryCode: params.countryCode,
      mobile: params.mobile,
      referrerBasePath: basePath
    }))
    .then(() => {
      history.navigate(basePath ? `/${basePath}/verify-email` : `/verify-email`, { replace: true })
    })
    .catch(message => {
      setIsSubmit(false)
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  const checkUserId = () => {
    if(!userId){
      setError('userId', { message: 'Required field' });
      return;
    } else if( userId.length > 20 || userId.length < 3 ) {
      setError('userId', { message: 'UserId must be between 3 and 20 characters long' });
      return;
    } else {
      setError('userId', { message: '' });
    }

    dispatch(existsEmail(`${userId}@${emailDomain}`))
    .then((res) => {
      if(res.isExistsEmail){
        setIsDupUserId(true);
      }else{
        setValue('isCheckUserId', true, { shouldValidate: true })
      }
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  const checkMobile = () => {
    if(!countryCode){
      setError('countryCode', { message: 'Required field' });
      return;
    } else if(!mobile){
      setError('mobile', { message: 'Required field' });
      return;
    } else if( mobile.length > 20 || mobile.length < 6 ) {
      setError('mobile', { message: 'This field must be between 8 and 20 numbers long' });
      return;
    } else {
      setError('mobile', { message: '' });
    }

    dispatch(existsMobile(`${countryCode} ${mobile}`))
    .then((res) => {
      if(res.isExistsMobile){
        setIsDupMobile(true)
      }else{
        setValue('isCheckMobile', true, { shouldValidate: true })
      }
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

const [focusedInput, setFocusedInput] = useState(null);

  const handleFocus = (inputName) => {
    setFocusedInput(inputName);
  };

  const handleBlur = () => {
    setFocusedInput(null);
  };

  const handleTicketNoticeLang = (lang) => {
    setTicketNoticeLang(lang);
  }

  const handleTicketNoticeClose = () => {
    setIsTicketNotice(false);
    setTicketNoticeLang('kr');
  }
  return (
    <>
      <div className="container_top ticket_top">
        <div className="top-info top-info_cn">
          <h2 className="top-title">Registration</h2>
        </div>
      </div>
      <div className='container container_register'>
        <div className='container_body-tit'>
          <h3></h3>
          <span className='tit-desc-box'><span className='tit-desc-box-p'>*</span> All fields are required </span>
        </div>
        <form className='form signup' onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div className='form_inner'>
            <div className={`${errors.userId?.message ? 'form_item is-invalid mb60' : 'form_item mb60'}`}>
              <label htmlFor='userId' className={`list_label ${focusedInput === 'userId' ? 'focused' : ''}`}><span className='tit-desc-p'>*</span> User ID <span></span> 아이디</label>
              <div className='form_item__wrap'>
                <div className='useId_wrap'>
                  <div className={`${errors.userId?.message ? 'is-invalid' : ''}`}>
                    <input
                      type='text'
                      id="userId"
                      name="userId"
                      onFocus={() => handleFocus('userId')}
                      onBlur={handleBlur}
                      style={{imeMode:'disabled'}}
                      {...register('userId',{
                      onChange: (e)=>{
                        e.target.value = e.target.value.toLowerCase();
                      }})}
                      placeholder='Enter your ID'
                      maxLength="20"
      
                    />
                    <div className='invalid-feedback'>{errors.userId?.message}</div>
                  </div>
                  <span>@</span>
                  <div className={`${errors.emailDomain?.message ? 'is-invalid' : ''}`}>
                    <select id="emailDomain" name="emailDomain" onFocus={() => handleFocus('userId')}
                  onBlur={handleBlur} {...register('emailDomain')}>
                      <option value='samsung.com'>samsung.com</option>
                      <option value='partner.samsung.com'>partner.samsung.com</option>
                      <option value="smartthings.com">smartthings.com</option>
                      <option value="sea.samsung.com">sea.samsung.com</option>
                      <option value="partner.sea.samsung.com">partner.sea.samsung.com</option>
                    </select>
                    <div className='invalid-feedback' style={{visibility:'hidden'}}>{errors.emailDomain?.message}</div>
                  </div>
                </div>
                <div className={`${errors.isCheckUserId?.message ? 'is-invalid' : ''}`}>
                  {
                    isCheckUserId ?
                      (<button className="btn-success" type="button" style={{position: 'relative'}}>Success</button>) :
                      (
                        <div className='chk-btn'>
                          <button type="button" className="btn-check" onClick={()=>checkUserId()}><span className="spsans">Check</span></button>
                          <input type='checkbox' id="isCheckUserId" name="isCheckUserId" {...register("isCheckUserId")} style={{display: 'none'}}/>
                        </div>
                      )
                  }
                  <div className='invalid-feedback'>{errors.isCheckUserId?.message}</div>
                </div>
              </div>
            </div>
            <div className='register_pw mb60'>
              <div className='form_item'>
                <label htmlFor='password' className={`list_label ${focusedInput === 'password' ? 'focused' : ''}`}><span className='tit-desc-p'>*</span> Password<span></span>비밀번호</label>
                <div className={`${errors.password?.message ? 'is-invalid mb30' : ''}`}>
                  <input
                    type='password'
                    id="password"
                    name="password"
                    onFocus={() => handleFocus('password')}
                    onBlur={handleBlur}
                    onPaste={e=>e.preventDefault()}
                    {...register('password',{
                      onChange: (e)=>{
                        e.target.value = e.target.value.replace(/[^A-Za-z0-9!@#$%&*^]/g,'');
                      }})}
                    placeholder='Enter your password'
                  />
                  <div className='invalid-feedback'>{errors.password?.message}</div>
                </div>
              </div>
              <div className='form_item'>
                <label htmlFor='confirmPassword' className={`list_label ${focusedInput === 'confirmPassword' ? 'focused' : ''}`}><span className='tit-desc-p'>*</span> Confirm password<span></span>비밀번호 확인</label>
                <div className={`${errors.confirmPassword?.message ? 'is-invalid mb30' : ''}`}>
                  <input
                    type='password'
                    id="confirmPassword"
                    name="confirmPassword"
                    onFocus={() => handleFocus('confirmPassword')}
                    placeholder='Confirm your password'
                    onBlur={handleBlur}
                    onPaste={e=>e.preventDefault()}
                    {...register('confirmPassword',{
                      onChange: (e)=>{
                        e.target.value = e.target.value.replace(/[^A-Za-z0-9!@#$%&*^]/g,'');
                      }})}
                  />
                  <div className='invalid-feedback'>{errors.confirmPassword?.message}</div>
                </div>
              </div>
            </div>
            <div className='form_item'>
              <label htmlFor='firstName' className={`list_label ${focusedInput === 'firstName' ? 'focused' : ''}`}><span className='tit-desc-p'>*</span> Name<span></span>이름</label>
              <div className='register_name'>
                <div className={`${errors.firstName?.message ? 'is-invalid mb30' : 'mb30'}`}>
                    <input
                      type='text'
                      id="firstName"
                      name="firstName"
                      maxLength="40"
                      onFocus={() => handleFocus('firstName')}
                      onBlur={handleBlur}
                      {...register('firstName',{
                        onChange: (e)=>{
                          e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣0-9a-zA-Z _.-]/g,'');
                        }})
                      }
                      placeholder='First name'
                    />
                    <div className='invalid-feedback'>{errors.firstName?.message}</div>
                </div>
                <div className={`${errors.lastName?.message ? 'is-invalid mb30' : 'mb30'}`}>
                  <input
                    type='text'
                    id="lastName"
                    name="lastName"
                    maxLength="20"
                    onFocus={() => handleFocus('firstName')}
                    onBlur={handleBlur}
                    {...register('lastName',{
                      onChange: (e)=>{
                        e.target.value = e.target.value.replace(/[^ㄱ-ㅎ가-힣0-9a-zA-Z _.-]/g,'');
                      }})}
                    placeholder='Last name'
                  />
                  <div className='invalid-feedback'>{errors.lastName?.message}</div>
                </div>
              </div>
            </div>
            <div className='register_corp mb60'>
            <div className='form_item mb30'>
              <label htmlFor='company' className={`list_label ${focusedInput === 'company' ? 'focused' : ''}`}><span className='tit-desc-p'>*</span> Company/Division<span></span>회사/사업부</label>
              <div className='form_item__wrap other__wrap'>
                <div className={`${errors.company?.message ? 'is-invalid' : ''}`}>
                  <select id="company" name="company" onFocus={() => handleFocus('company')}
                  onBlur={handleBlur} {...register('company')}>
                    <option value=''>Please select</option>
                    {
                      _.filter(Object.keys(companyData),key => key !== 'Third party(협력사)').map(key => (
                        <option value={key}>{key}</option>
                      ))
                    }
                  </select>
                  <div className='invalid-feedback'>{errors.company?.message}</div>
                </div>
                {
                  (
                    <div className={`${errors.division?.message ? 'is-invalid ml20' : 'ml20'}`}>
                      <select id="division" name="division" onFocus={() => handleFocus('company')}
                        onBlur={handleBlur}{...register('division',{
                          onChange: (e)=>{
                            setValue('division_other', '')
                          }
                        })}>
                        <option value=''>Please select</option>
                        {
                          company && companyData[company].map(name => (
                            <option value={name}>{name}</option>
                          ))
                        }
                      </select>
                      <div className='invalid-feedback'>{errors.division?.message}</div>
                    </div>
                  )
                }
                {division === 'Other' && <div className={`other__input ${errors.division_other?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='text'
                    id="division_other"
                    name="division_other"
                    maxLength="20"
                    onFocus={() => handleFocus('division_other')}
                    onBlur={handleBlur}
                    {...register('division_other')}
                    placeholder="Division/사업부"
                  />
                  <div className='invalid-feedback'>{errors.division_other?.message}</div>
                </div>}
              </div>
            </div>
            <div className='form_item mb30'>
              <label htmlFor='region' className={`list_label ${focusedInput === 'region' ? 'focused' : ''}`}><span className='tit-desc-p'>*</span> Region/Organization<span></span>지역/법인</label>
              <div className='form_item__wrap other__wrap'>
                <div className={`${errors.region?.message ? 'is-invalid' : ''}`}>
                  <select id="region" name="region" onFocus={() => handleFocus('region')}
                  onBlur={handleBlur} {...register('region')}>
                    <option value=''>Please select</option>
                    {
                      Object.keys(regionData).map(key => (
                        <option value={key}>{key}</option>
                      ))
                    }
                  </select>
                  <div className='invalid-feedback'>{errors.region?.message}</div>
                </div>
                {
                   (
                    <div className={`${errors.organization?.message ? 'is-invalid ml20' : 'ml20'}`}>
                      <select id="organization" name="organization" onFocus={() => handleFocus('region')}
                      onBlur={handleBlur} {...register('organization',{
                        onChange: (e)=>{
                          setValue('organization_other', '')
                          setValue('countryCode', regionData[region][e.target.value])
                        }
                      })}>
                        <option value=''>Please select</option>
                        {
                          region && Object.keys(regionData[region]).map(key => (
                            <option value={key}>{key}</option>
                          ))
                        }
                      </select>
                      <div className='invalid-feedback'>{errors.organization?.message}</div>
                    </div>
                  )
                }
                {/*<div className={`${errors.organization?.message ? 'is-invalid ml20' : 'ml20'}`}>
                  <input
                    type='text'
                    id="organization"
                    name="organization"
                    maxLength="20"
                    onFocus={() => handleFocus('organization')}
                    onBlur={handleBlur}
                    {...register('organization')}
                    placeholder='Organization'
                  />
                  <div className='invalid-feedback'>{errors.organization?.message}</div>
                </div>
                */}
                {organization === 'Other' && <div className={`other__input ${errors.organization_other?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='text'
                    id="organization_other"
                    name="organization_other"
                    maxLength="20"
                    {...register('organization_other')}
                    placeholder="Organization/법인"
                  />
                  <div className='invalid-feedback'>{errors.organization_other?.message}</div>
                  <div  className='invalid-feedback-ex'>
                    <span>Ex)</span> NA Office, SEA, SEG, SEI, SEIL
                  </div>
                </div>}
              </div>
            </div>
            </div>
            <div className='form_item mb60'>
              <label htmlFor='countryCode' className={`list_label ${focusedInput === 'mobile' ? 'focused' : ''}`}><span className='tit-desc-p'>*</span> Mobile contact point<span></span>연락처</label>
              <div className='form_item__wrap'>
                <div className={`${errors.countryCode?.message ? 'is-invalid' : ''}`}>
                  <select id="countryCode" name="countryCode" onFocus={() => handleFocus('mobile')}
                  onBlur={handleBlur} {...register('countryCode')}>
                    <option value=''>Please select</option>
                    {
                      mobileData.map(value => (
                        <option value={value}>{value}</option>
                      ))
                    }
                  </select>
                  <div className='invalid-feedback'>{errors.countryCode?.message}</div>
                </div>
                <div className={`${errors.mobile?.message ? 'is-invalid' : ''}`}>
                  <input
                    type='text'
                    id="mobile"
                    name="mobile"
                    onFocus={() => handleFocus('mobile')}
                    onBlur={handleBlur}
                    {...register('mobile',{
                      onChange: (e)=>{
                      e.target.value = e.target.value.replace(/[^0-9]/g,'');
                      // if(e.target.value.length > 0 && e.target.value.substring(0,1) === '0') {
                      //   e.target.value = e.target.value.substring(1,e.target.value.length)
                      // }
                    }})}
                    placeholder='Enter your mobile number'
                  />
                  <div className='invalid-feedback'>{errors.mobile?.message}</div>
                </div>
                <div className={`${errors.isCheckMobile?.message ? 'is-invalid' : ''}`}>
                  {
                    isCheckMobile ?
                      (<button className="btn-success" type="button"><span className="spsans">Success</span></button>) :
                      (
                        <div className='chk-btn'>
                          <button type="button" className="btn-check" onClick={()=>checkMobile()} ><span className="spsans">Check</span></button>
                          <input type='checkbox' id="isCheckMobile" name="isCheckMobile" {...register("isCheckMobile")} style={{display: 'none'}}/>
                        </div>
                      )
                  }
                  <div className='invalid-feedback'>{errors.isCheckMobile?.message}</div>
                </div>
              </div>
            </div>
            <div className='form_item mb60'>
              <label htmlFor='email' className={`list_label ${focusedInput === 'email' ? 'focused' : ''}`}>이메일(가입인증 / 알림 이메일수신 테스트용)</label>
              <div className={`${errors.email?.message ? 'is-invalid' : ''}`}>
                <input
                  type='email'
                  id="email"
                  name="email"
                  onFocus={() => handleFocus('email')}
                  onBlur={handleBlur}
                  {...register('email')}
                  placeholder='이메일을 입력해 주세요.'
                />
                {/* <div className=''>최종 배포시 삭제됩니다.(이메일 수신테스트 용도입니다..)</div> */}
                <div className='invalid-feedback'>{errors.email?.message}</div>
              </div>
          </div>
          </div>
          <span className='bar mb60'></span>
          <div className='buttons_center'>
            <Link to="/" className='btn-cancel mr20' style={{backgroundColor:'#000'}}><span className='spsans'>Cancel</span></Link>
            <button type="submit" className='btn-submit' disabled={isSubmit}><span className='spsans'>Submit</span></button>
          </div>
        </form>
      </div>
      <div className='modal fade in' id='modalEmailDbchk' style={{display: isDupUserId ? 'block' : ''}}>
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content modal-content-p0'>
              <div className='modal-body'>
                <p>This ID is already in use.</p>
                <span>사용중인 아이디입니다.</span>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn-send sssb' onClick={()=>setIsDupUserId(false)}>Done</button>
              </div>
            </div>
          </div>
      </div>

      <div className='modal fade in' id='modalEmailDbchk' style={{display: isDupMobile ? 'block' : 'none'}}>
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content modal-content-p0'>
              <div className='modal-body'>
                <p>This phone number is already in use.</p>
                <span>등록된 휴대폰번호 입니다.</span>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn-send sssb' onClick={()=>setIsDupMobile(false)}>Done</button>
              </div>
            </div>
          </div>
      </div>

      <div className="modal fade in" id="modalTicketNotice" style={{display: isTicketNotice ? 'none' : 'none'}}>
        <div className='modal-dialog modal-dialog-size modal-dialog-centered'>
          <div className='modal-content modal-content-p5'>
            {/* 
            <div className="container_lang">
              <button  className={`btn-en ${lang === 'en' ? 'on':''}`} onClick={() => setLang('en')}><span className="spsans">EN</span></button>
              <button  className={`btn-kr ${lang === 'kr' ? 'on':''}`} onClick={() => setLang('kr')}><span className="spsans">KR</span></button>
            </div>
            */}
            <div className="container_lang">
              <button  className={`btn-en ${ticketNoticeLang == 'en'?'on':''}`} onClick={()=>handleTicketNoticeLang('en')}><span className="spsans">EN</span></button>
              <button  className={`btn-kr ${ticketNoticeLang == 'kr'?'on':''}`} onClick={()=>handleTicketNoticeLang('kr')}><span className="spsans">KR</span></button>
            </div>
            { ticketNoticeLang === 'kr' ?
            <>
            <div className='modal-body  register-modal-body'>
              <div className='register-modal-container'>
                <div className='register-modal-bx register-modal-sp1'>
                  <span className='rmb-dv_01'>1</span>
                  <div className='rmb-dv_02'>
                    IFA  <span className='inner_00'>2025</span><br/>
                    <span className='inner_01'>Reservation System</span>
                  </div>
                  <div className='rmb-dv_03'>
                    티켓 신청
                  </div>
                  <div className='rmb-dv_04'>
                    <div className='inner_01'>8.13<span>~</span>8.23</div>
                    <div className='inner_02'>
                      회원가입 후<br />
                      Ticket Request에서<br />
                      신청해 주세요
                    </div>
                  </div>
                </div>
                <div className='register-modal-bx register-modal-sp2'>
                  <span className='rmb-dv_01'>2</span>
                  <div className='rmb-dv_02'>
                    Approval<br/>
                    By E-mail
                  </div>
                  <div className='rmb-dv_03'>
                    티켓 신청 승인
                  </div>
                  <div className='rmb-dv_04'>
                    <div className='inner_01'>8.13<span>~</span>8.23</div>
                    <div className='inner_02'>
                      티켓 신청 승인 결과 안내<br />
                      E-mail 전송 기간
                    </div>
                  </div>
                </div>
                <div className='register-modal-bx register-modal-sp3'>
                  <span className='rmb-dv_01'>3</span>
                  <div className='rmb-dv_02'>
                    <img src={iconIFAlogo} alt="reservation" className="" />
                  </div>
                  <div className='rmb-dv_03'>
                    IFA Management에서<br/>
                    IFA 티켓 발송
                  </div>
                  <div className='rmb-dv_04'>
                    <div className='inner_01'>8.16<span>~</span>8.30</div>
                    <div className='inner_02'>
                        승인된 출장자에 한하여<br/>
                        신청 시 입력한 E-mail로<br/>IFA 티켓 전송<br/>
                        <br/>
                        <span className='tx1'>※ 행사 직전 IFA Management의 안내 메일이 다시 한번 발송될 예정입니다</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='register-modal-text'>
                <div className='inner_div'>
                  <span className='tx1'>※</span>
                  본사 출장자를 위한 티켓 사전 신청 안내이며, 사이트 및 <span className='tx-point'>티켓 신청 링크 유출을 엄격히 금합니다.</span>
                </div>
                <div className='inner_div'>
                  <span className='tx1'>※</span>
                  사전 신청 사이트를 통해 티켓을 신청하지 않은 인원 또는 삼성전자 외부인에게 <span className='tx-point'>링크를 유출할 시,</span> 담당자 및 <span className='tx-point'>관련자에게 불이익</span>이 있을 수 있습니다.
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn-send2 sssb' onClick={handleTicketNoticeClose}>공지 사항을 확인 하였기에 팝업을 닫습니다.</button>
            </div>
            </>
            :
            <>
            <div className='modal-body  register-modal-body'>
              <div className='register-modal-container'>
                <div className='register-modal-bx register-modal-sp1'>
                  <span className='rmb-dv_01'>1</span>
                  <div className='rmb-dv_02'>
                    IFA  <span className='inner_00'>2025</span><br/>
                    <span className='inner_01'>Reservation System</span>
                  </div>
                  <div className='rmb-dv_03'>
                  Ticket Request
                  </div>
                  <div className='rmb-dv_04'>
                    <div className='inner_01'>8.13<span>~</span>8.23</div>
                    <div className='inner_02'>
                    Register as a member and then apply through the<br/>Ticket Request page
                    </div>
                  </div>
                </div>
                <div className='register-modal-bx register-modal-sp2'>
                  <span className='rmb-dv_01'>2</span>
                  <div className='rmb-dv_02'>
                    Approval<br/>
                    By E-mail
                  </div>
                  <div className='rmb-dv_03'>
                  Ticket Approval
                  </div>
                  <div className='rmb-dv_04'>
                    <div className='inner_01'>8.13<span>~</span>8.23</div>
                    <div className='inner_02'>
                    Period for sending ticket approval result email
                    </div>
                  </div>
                </div>
                <div className='register-modal-bx register-modal-sp3'>
                  <span className='rmb-dv_01'>3</span>
                  <div className='rmb-dv_02'>
                    <img src={iconIFAlogo} alt="reservation" className="" />
                  </div>
                  <div className='rmb-dv_03'>
                    Sending your IFA ticket 
                    from <br/>IFA Management
                  </div>
                  <div className='rmb-dv_04'>
                    <div className='inner_01'>8.16<span>~</span>8.30</div>
                    <div className='inner_02'>
                        IFA tickets will be sent via email to the approved travelers by<br/>IFA Management.
                        <br/>
                        <span className='tx1'>※ A reminder email from IFA Management will be sent again just before the event</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='register-modal-text'>
                <div className='inner_div'>
                  <span className='tx1'>※</span>
                  This is a guide to pre application for tickets for HQ employees, and <span className='tx-point'>the leakage of sites and ticket application links is strictly prohibited.</span>
                </div>
                <div className='inner_div'>
                  <span className='tx1'>※</span>
                  If a link is leaked to a person who has not applied for a ticket through a pre application site or to an outsider of Samsung Electronics, <span className='tx-point'>the person in charge and related parties will get disadvantages.</span>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn-send2 sssb' onClick={handleTicketNoticeClose}>By confirming this notice, you may close the pop-up</button>
            </div>     
            </>
            }
          </div>
        </div>
      </div>
    </>
  );
}