import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { times, rooms } from '@/data/meetingroom';
import { toast } from 'react-toastify';
import ReconnectingWebSocket from 'reconnecting-websocket';
import ImageMapper from 'react-image-mapper';
import { useMediaQuery } from 'react-responsive'
import _ from 'lodash';
import config from '@/config';
import Skeleton from 'react-loading-skeleton'
import history from '@/helpers/history';
import { createReservation, status, myStatus } from '@/actions/room';
import roomA from '@/assets/images/BizLoungeA.png';
import roomB from '@/assets/images/BizLoungeB.png';
import 'react-loading-skeleton/dist/skeleton.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick";
import Moment from 'react-moment';

export default function RoomTimeTable() {
  const dispatch = useDispatch();
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 766px)'
  })
  // const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isMediumDevice = useMediaQuery({ query: '(max-width: 765px)' })
  const isSmallDevice = useMediaQuery({ query: '(max-width: 415px)' })

  const settings = {
    centerMode: true,
    centerPadding: '130px',
    slidesToShow: 1,
    afterChange: current => {
      setDateIndex(current)
      setToday(times[current])
    }
  };
  
  const [selectTimeData, setSelectTimeData] = useState({
    // date: dateIndex,
    // dateLabel: times[dateIndex].date,
    startTime: '',
    startTimeLabel: '',
    endTime: '',
    endTimeLabel: '',
    room: '',
    time: '',
    selectTimeCheck: 0,
  });

  const [isSelectTimeModal, setIsSelectTimeModal] = useState(false);
  const [isCantReservationModal, setIsCantReservationModal] = useState(false);
  
  const [dateIndex, setDateIndex] = useState(0);
  const [today, setToday] = useState(times[dateIndex]);
  const [table, setTable] = useState(null);
  const [timeTableStatusMap, setTimeTableStatusMap] = useState(null);
  const [myStatusData, setMyStatusData] = useState(null);
  const [roomMap, setRoomMap] = useState(null);

  const makeTableStatusData = (items) => {
    const timetable_data = _.groupBy(items, row => row.room);
    
    let tableStatusMap = {}

    for(let timeIndex=0; timeIndex< today.startTimes.length; timeIndex++) {
      for (const [key, value] of Object.entries(timetable_data)) {
        if(!tableStatusMap[key]){
          tableStatusMap[key]={}
        }
        const findItem = _.find(value, row => row.startTime === timeIndex);
        if(findItem){
          tableStatusMap[key][timeIndex]={
            ...findItem,
            gap: (findItem.endTime - findItem.startTime) + 1,
            can: false
          }
          for(let i=findItem.startTime+1; i<=findItem.endTime; i++){
            tableStatusMap[key][i] = {
              skip: true,
              can: false
            }
          }
        }else {
          tableStatusMap[key][timeIndex]={
            can: true,
            ...tableStatusMap[key][timeIndex]
          }
        }
      }
    }
    setTimeTableStatusMap(tableStatusMap)
  }

  useEffect(()=>{
    setTable(_.sortBy(_.map(_.groupBy(rooms, room => room.capacity), (info, capacity) => ({ info, capacity }))))
  }, [])

  useEffect(() => {
    setTimeTableStatusMap(null);

    dispatch(status(dateIndex))
    .then((res) => {
      makeTableStatusData(res)
    })

    dispatch(myStatus(dateIndex))
    .then((res) => {
      setMyStatusData(res)
    })
  },[dateIndex])

  useEffect(() => {
    const webSocketUrl = `${config.WEBSOCKET_HOST}/room-table-status`
    const rws = new ReconnectingWebSocket(webSocketUrl);

    rws.addEventListener('message', res => {
      makeTableStatusData(_.filter(JSON.parse(res.data), r=>r.ndate === parseInt(dateIndex)));
    });

    return () => {
      rws.close();
    };
  }, [dateIndex]);

  const onReservation = (roomInfo, time, timeIndex) => {
    if(!myStatusData.canReservation){
      setIsCantReservationModal(true);
      return;
    }

    setSelectTimeData({
      ...selectTimeData,
      date: dateIndex,
      dateLabel: times[dateIndex].date,
      room:roomInfo.room,
      roomMapImg:roomInfo.map,
      time,
      startTime: timeIndex,
      endTime: timeIndex,
      startTimeLabel: today['startTimes'][timeIndex],
      endTimeLabel: today['endTimes'][timeIndex],
      minutes: 30,
      hasNext: !timeTableStatusMap[roomInfo.room] ? timeIndex !== today['startTimes'].length -1 ?  true : false : timeTableStatusMap[roomInfo.room][timeIndex+1]?.can ,
      selectTimeCheck: 0
    })

    setRoomMap({
      name: "room",
      areas: [
        {
          name: "1",
          shape: roomInfo.shape,
          coords: roomInfo.coords,
          preFillColor: "rgb(0,166,255,0.2)",
        }
      ]
    })
  }

  const onReservationSubmit = () => {
    dispatch(createReservation(selectTimeData))
    .then((res) => {
      history.navigate(`/room/reservation/${res.id}/create`, {replace: true})
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
    setIsSelectTimeModal(false);
  }

  const onTimeChange = (e) => {
    const { value } = e.target;
    setSelectTimeData({
      ...selectTimeData,
      endTime: parseInt(selectTimeData.startTime) + parseInt(value),
      endTimeLabel: today['endTimes'][parseInt(selectTimeData.startTime) + parseInt(value)],
      minutes: 30 + (30 * parseInt(value)),
      selectTimeCheck: parseInt(value)
    })
  };

  return (
    <>
      <div className="timetable_date">
        <Slider {...settings}>
          {
            times.map((time, index)=>(
              <div key={time.date}>
                {time.label}
              </div>
            ))
          }
        </Slider>
      </div>
      {timeTableStatusMap ? (
        <>
        <table className="room_timetable mb20">
          <thead>
            <tr>
              <th scope="col" rowSpan="2" className="bb0 one">Pax</th>
              <th scope="col" rowSpan="2" className="bb0 one">Number</th>
              {
                today.hours.map((hour)=>(
                  <th scope="col" colSpan="2" className='one' key={hour}>{hour}</th>
                ))
              }
            </tr>
            <tr>
              {
                today.startTimes.map((time, tindex)=>(
                  <th scope="col" className='two' key={tindex}>{time.split(':')[1]}</th>
                ))
              }
            </tr>
          </thead>
          <tbody>
            {
              table.map((group, n) => (
                <React.Fragment key={n}>
                  {
                    group.info.map((row, index) => (
                      <tr key={`${group.capacity}-${row.room}`}>
                        {index === 0 && <td rowSpan={group.info.length} className="br one">{ group.capacity }</td>}
                        <td className="table_point two">{ row.room }</td>
                        {
                          today.startTimes.map((time, timeIndex)=>(
                            !timeTableStatusMap?.[row.room]?.[timeIndex]?.skip && (
                              <td colSpan={timeTableStatusMap?.[row.room]?.[timeIndex]?.gap} key={`${group.capacity}-${row.room}-${time}`}>
                                { timeTableStatusMap?.[row.room]?.[timeIndex]?.status === 'InForm' && (<button className="time_nope"></button>)}
                                { timeTableStatusMap?.[row.room]?.[timeIndex]?.status === 'In Progress' && (<button className="time_nope"></button>)}
                                { timeTableStatusMap?.[row.room]?.[timeIndex]?.status === 'Approved' && (<button className="time_process"></button>)}
                                { !timeTableStatusMap?.[row.room]?.[timeIndex]?.status && (<button className="time_blank" onClick={()=>onReservation(row, time, timeIndex)}>O</button>)}
                              </td>
                            )
                          ))
                        }
                      </tr>
                    ))
                  }
                </React.Fragment>
              ))
            }
          </tbody>
        </table>
        <div className="timetable_label-wrap mb40">
        <div className="timetable_label-div">
          <span className="timetable_label timetable_label-appr"></span>
          <strong>approved</strong>
        </div>
        <div className="timetable_label-div">
          <span className="timetable_label timetable_label-blank"></span>
          <strong>available</strong>
        </div>
        <div className="timetable_label-div">
          <span className="timetable_label timetable_label-nope"></span>
          <strong>unavailable</strong>
        </div>
      </div>
      <div className="room_notice-wrap">
        <div className="room_notice-text">
          <strong className="mb20">Please note that maximum 2 slots per a day is available.</strong>
          <p>- Click the empty areas to make a reservation.</p>
          <p>- It takes 5 minutes of processing time to release the room after cancellation.</p>
        </div>
        <div className="room_notice-text">
          <strong className="mb20">1일 최대 2슬롯 예약이 가능합니다.</strong>
          <p>- 빈 칸을 클릭하여 예약하십시오.</p>
          <p>- 취소 후 회의실 해제는 처리 시간이 5분 소요됩니다.</p>
        </div>
      </div>
        </>
      ):(<Skeleton height={30} count={rooms.length} />)}
      <div className="modal fade in" id="modalTimeChosen" style={{display: isSelectTimeModal ? 'block' : ''}}>
        <div className={`modal-dialog modal-dialog-centered ${isDesktopOrLaptop ? 'modal-dialog_lg' : ''}`}>
          <div className="modal-content modal-content-p0">
            <div className="modal-body">
                <h3 className='mb20'><Moment format='DD.MMM.YYYY'>{today.date}</Moment></h3>
                <p className="mb10">{selectTimeData && selectTimeData.room} Conference room has been selected.<br/>{selectTimeData && selectTimeData.room} 회의실이 선택 되었습니다.</p>
                <p className="mb30">Please select a meeting duration option below.<br />아래에서 회의실을 사용하실 시간을 선택해 주세요.</p>
                <div className='mb30 room_modal_img'>
                  {roomMap && selectTimeData?.roomMapImg === 'room-c' && (<ImageMapper onLoad={() => setIsSelectTimeModal(true)} src={roomA} map={roomMap} width={isSmallDevice ? 220 : isMediumDevice ? 600 : 585} imgWidth={585}/>)}
                  {/* {roomMap && selectTimeData?.roomMapImg === 'room-2' && (<ImageMapper onLoad={() => setIsSelectTimeModal(true)} src={roomB} map={roomMap} width={isSmallDevice ? 220 : isMediumDevice ? 600 : 477} imgWidth={474}/>)} */}
                </div>
                <div className="hoursChosen_wrap">
                  <div className="hoursChosen mb20">
                    <input type="radio" name="hoursChosen" id="use30min" onChange={onTimeChange} value="0" checked={selectTimeData.selectTimeCheck === 0}/>
                    <label htmlFor="use30min">30 minutes<br/>30분 사용하기</label>
                  </div>
                  <div className="hoursChosen" style={{display: selectTimeData.hasNext ? '' : 'none'}}>
                    <input type="radio" name="hoursChosen" id="usehour" onChange={onTimeChange} value="1" checked={selectTimeData.selectTimeCheck === 1}/>
                    <label htmlFor="usehour">1 hour<br/>1시간 사용하기</label>
                  </div>
                </div>
                <p>
                  You have selected : {selectTimeData?.startTimeLabel} to {selectTimeData?.endTimeLabel}<br />
                  회의실 사용 시간 : {selectTimeData?.startTimeLabel} to {selectTimeData?.endTimeLabel}
                </p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn-cancel mr20" onClick={()=>{setRoomMap(null); setIsSelectTimeModal(false);}}>Cancel</button>
              <button className="btn-submit" onClick={()=>onReservationSubmit()}>Done</button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade in' id='cantReservation' style={{display: isCantReservationModal ? 'block' : ''}}>
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content modal-content-p0'>
              <div className='modal-body'>
                <strong>maximum 2 slots per a day is available</strong>
                <p className='fw-md'>1일 최대 2슬롯 예약이 가능합니다.</p>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn-send' onClick={()=>setIsCantReservationModal(false)}>OK</button>
              </div>
            </div>
          </div>
      </div>
    </>
  );
}
