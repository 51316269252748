import Header from '@/components/Header';
import Footer from '@/components/Footer';
import { useState, useEffect } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import history from '@/helpers/history';

export default function Layout() {
  const location = useLocation();
  const [title, setTitle] = useState('');

  useEffect(() => {
    switch (location.pathname) {
      case '/viptour/information/':
      case '/viptour/information':
      case '/room/information/':
      case '/room/information':
        setTitle('Information');
        break;
      case '/viptour/reservation/':
      case '/viptour/reservation':
        setTitle('VIP tour reservation');
        break;
      case '/room/reservation/timetable/':
      case '/room/reservation/timetable':
        setTitle('Add reservation');
        break;
      case '/viptour/reservation-list/':
      case '/viptour/reservation-list':
      case '/room/reservation-list/':
      case '/room/reservation-list':
        setTitle('Reservation list');
        break;
      case '/viptour/faq/':
      case '/viptour/faq':
      case '/room/faq/':
      case '/room/faq':
      case '/faq/':
      case '/faq':
        setTitle('Frequently asked questions');
        break;
      case '/viptour/mypage/':
      case '/viptour/mypage':
      case '/mypage/':
      case '/mypage':
        setTitle('My page');
        break;
      case '/viptour/register/':
      case '/viptour/register':
      case '/register/':
      case '/register':
        setTitle('Registration');
        break;
      default:
    }
  }, [location.pathname]);

  return (
    <>
      <Header />
      {
        history.location.pathname.toLowerCase() === '/viptour' || history.location.pathname.toLowerCase() === '/viptour/' || history.location.pathname.toLowerCase() === '/room' || history.location.pathname.toLowerCase() === '/room/' || history.location.pathname.toLowerCase() === '/' ? (
          <Outlet />
        ) : (
            <div className="" style={{minHeight:'calc(100vh - 138px)',background:'#fff'}}>
              {/* <div className="container_top ticket_top">
                <div className="top-info">
                  <h2 className="top-title">{title}</h2>
                </div>
              </div> */}

              <div className="container container_ticket container_tour">
                <Outlet />
              </div>
          </div>
        )
      }
      <Footer />
    </>
  );
}