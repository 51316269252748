import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { verifyEmail, resendVerifyEmail } from "@/actions/auth";
import { toast } from 'react-toastify';
import history from '@/helpers/history';

export default function VerifyEmail() {
  const dispatch = useDispatch();
  const { basePath } = useSelector((state) => state.common);

  const [lang, setLang] = useState('kr');
  const [isRequestVerifyEmail, setIsRequestVerifyEmail] = useState(false);
  //
  const queryString = window.location.search;
  const parameters = new URLSearchParams(queryString);
  const tokenValue = parameters.get('token');
  const currentUser = useSelector(state => state.user);

  useEffect(() => {
    if (tokenValue) {
      if(currentUser?.user?.isVerified) {
        history.navigate(basePath ? `/${basePath}/information` : `/information`, { replace: true })
        // history.navigate(`/viptour/information`, { replace: true })
        return;
      } else {
        dispatch(verifyEmail(tokenValue))
        .then(data => {
          history.navigate(data.referrerBasePath ? `/${data.referrerBasePath}/information` : `/information`, { replace: true })
          // history.navigate(`/viptour/information`, { replace: true })
          return;
        })
        .catch(message => {
          history.navigate(basePath ? `/${basePath}` : `/`, { replace: true })
          // history.navigate(`/viptour`, { replace: true })
        });
      }
    } else if (!currentUser?.isLoggedIn) {
      history.navigate(basePath ? `/${basePath}` : `/`, { replace: true })
      // history.navigate(`/viptour`, { replace: true })
    }
  }, [tokenValue])

  const onResendVerifyEmail = () => {
    setIsRequestVerifyEmail(true)
    dispatch(resendVerifyEmail())
    .then(response => {
      setIsRequestVerifyEmail(false)
      if(response.isVerified)
      {
        history.navigate(basePath ? `/${basePath}/information` : `/information`, { replace: true })
        // history.navigate(`/viptour/information`, { replace: true })
        toast.success('already verified account', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
        });
      }else{
        toast.success('Please check your mailbox', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
        });
      }
      
    })
    .catch(message => {
      history.navigate(basePath ? `/${basePath}` : `/`, { replace: true })
      // history.navigate(`/viptour`, { replace: true })
    });
  }

  return (
    <>
      {
        currentUser && (
          <div>
            <div className="container_top ticket_top">
              <div className="top-info top-info_cn">
                <h2 className="top-title">Registration</h2>
              </div>
            </div>
            <div className="container container_registered">
                <div className="container_lang-wrap">
                  <div className="container_lang">
                    <button className={`btn-en ${lang === 'en' ? 'on':''}`} onClick={() => setLang('en')}><span className="spsans">EN</span></button>
                    <button className={`btn-kr ${lang === 'kr' ? 'on':''}`} onClick={() => setLang('kr')}><span className="spsans">KR</span></button>
                  </div>
                </div>
                {
                  lang === 'en' ? (
                    <section className="registered_en">
                      <h3 className="ssone"><span>{currentUser?.user.firstName} {currentUser?.user.lastName}</span>, welcome to join.</h3>
                      <p>We have sent a link to your email <span>{currentUser?.user.email}</span> to confirm your account.<br/>
                If the email has not arrived, please click the [Resend verification email] button below.<br/>Otherwise, press [Home] to return to the main page.</p>
                    </section>
                  ) : (
                    <section className="registered">
                      <h3 className="ssone"><span>{currentUser?.user.firstName} {currentUser?.user.lastName}</span>님의 가입을 환영합니다</h3>
                      <p>회원가입 시 등록해 주신 {currentUser?.user.email}로 회원가입 인증 메일을 전송하였습니다.<br/>
                만약 인증 메일을 받지 못하셨다면 아래 인증메일 재전송 버튼을 클릭해 주세요!</p>
                    </section>
                  )
                }
                {
                  lang ==='en' ? (
                <div className="buttons_center mb100">
                  <button className="btn-email mr10 btn_mx" onClick={()=>onResendVerifyEmail()} disabled={isRequestVerifyEmail}>Resend verification email</button>
                  <Link to={`/${basePath}`}><button className="btn-home btn_mx">Home</button></Link>
                </div>
                  ) : (
                  <div className="buttons_center mb100">
                    <button className="btn-email mr10" onClick={()=>onResendVerifyEmail()}>인증메일 재전송</button>
                    <Link to={`/${basePath}`}><button className="btn-home">홈으로</button></Link>
                  </div>
                  )
                }
                <span className="bar-b"></span>
            </div>
          </div> 
        )
      }
    </>
  );
}
