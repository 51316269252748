import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <div className='not_found'>
      <div className='not_found_contents'>
        <div className='not_found_title'>
          <h1>CES 2025</h1>
          <h3>404 NotFound</h3>
        </div>
        <div className='not_found_btn_wrap'>
          <Link to='/' replace='true'><button className='btn-home'>Home</button></Link>
        </div>
      </div>
    </div>
  );
}
