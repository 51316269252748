import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { logout } from "@/actions/auth";
import LogoIcon from '@/assets/images/logo-w.svg';
import LogoIconB from '@/assets/images/logo.svg';
import history from '@/helpers/history';

function Header(props) {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.user);

  const { basePath } = useSelector((state) => state.common);

  const logOut = () => {
    dispatch(logout()).then(
      () => {
        history.navigate(basePath ? `/${basePath}` : `/`, { replace: true })
      }
    );
  };

  return (
      ['/', '/viptour/', '/viptour', '/room/', '/room'].indexOf((history.location.pathname.toLowerCase() === '/' ? history.location.pathname.toLowerCase() : history.location.pathname.toLowerCase().replace(/\/+$/, ''))) > -1 ?
      <header id="header" className={`bg_white`}>
        <div className="header_logo">
          <Link to={`/`}><img src={LogoIconB} alt="SAMSUNG" /></Link>
        </div>
      </header>
      :
      <header id="header">
      <div className="header_logo">
        <Link to={`/`}><img src={LogoIcon} alt="SAMSUNG" /></Link>
      </div>
      {
          currentUser?.isLoggedIn && ( 
            history.location.pathname.toLowerCase().indexOf('/viptour') > -1 ? 
            <>
                <div className="header_nav">
                  <ul className='menu'>
                      <li className='depth1'>
                        {/* <NavLink to="/viptour/information" className={() => ['/viptour/information','/viptour/reservation','/viptour/reservation-list'].indexOf(history.location.pathname.toLowerCase()) > -1 ? 'active' : 'inactive'}><span className="spsans">VIP Tour</span></NavLink>
                        <ul className='depth2'> */}
                          <li><NavLink to="/viptour/information">Information</NavLink></li>
                          <li><NavLink to="/viptour/reservation">VIP tour reservation</NavLink></li>
                          <li><NavLink to="/viptour/reservation-list">Reservation list</NavLink></li>
                        {/* </ul> */}
                      </li>
                      <li><NavLink to="/viptour/faq" className="btn-faq"><span className="spsans">FAQ</span></NavLink></li>
                  </ul>
                </div>
                <div className="header_links">
                  <Link to="/viptour/mypage">My page</Link> 
                  <span className="links-acc-bar"></span>
                  <button type="button" onClick={()=>logOut()}>Logout</button>
                </div>
            </> : 
            history.location.pathname.toLowerCase().indexOf('/ticket') > -1 ? 
            <>
                <div className="header_nav">
                  <ul className='menu'>
                      <li className='depth1'>
                        <li><NavLink to="/ticket/information">Information</NavLink></li>
                        <li><NavLink to="/ticket/request">Ticket request</NavLink></li>
                        <li><NavLink to="/ticket/ticket-list">Ticket list</NavLink></li>
                      </li>
                      <li><NavLink to="/ticket/faq" className="btn-faq"><span className="spsans">FAQ</span></NavLink></li>
                  </ul>
                </div>
                <div className="header_links">
                  <Link to="/ticket/mypage">My page</Link>
                  <span className="links-acc-bar"></span>
                  <button type="button" onClick={()=>logOut()}>Logout</button>
                </div>
            </> :
            <>
              <div className="header_nav">
                <ul className='menu'>
                  <li className='depth1'>
                      <li><NavLink to="/room/information">Information</NavLink></li>
                      <li><NavLink to="/room/reservation/timetable">Add reservation</NavLink></li>
                      <li><NavLink to="/room/application-list">Application list</NavLink></li>
                  </li>
                  <li><NavLink to="/room/faq" className="btn-faq"><span className="spsans">FAQ</span></NavLink></li>
                </ul>
              </div>
              <div className="header_links">
                <Link to="/room/mypage">My page</Link>
                <span className="links-acc-bar"></span>
                <button type="button" onClick={()=>logOut()}>Logout</button>
              </div>
            </>
          )
        }
    </header>    
  );
}

export default Header;
