import { useState } from 'react';
import tourMap from '@assets/images/tour-route_2025.png';

export default function TourInfomation() {
  const [lang, setLang] = useState('kr');

  return (
    <>
      <div className="container_top ticket_top">
        <div className="top-info top-info_cn">
          <h2 className="top-title">Information</h2>
        </div>
      </div>
      <div className="container_body-tit mb60">
        <h3></h3>
        <div className="container_lang">
          <button className={`btn-en ${lang === 'en' ? 'on':''}`} onClick={() => setLang('en')}><span className="spsans">EN</span></button>
          <button className={`btn-kr ${lang === 'kr' ? 'on':''}`} onClick={() => setLang('kr')}><span className="spsans">KR</span></button>
        </div>
      </div>
            {
        lang === 'en' ? (
          <section className="information_en">
            <div className="information_announce">
              <div className="information_inner">
                <h4>Notice</h4>
              </div>
              <div className="information_inner">
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> Welcome to the Samsung CES VIP tour reservation page.</p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> Please be advised before making a reservation that the Exclusive Tour offers limited slots, so only exclusive guests who are hosted by Samsung executives; EVP, Head of Regional Office or higher.</p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> Tours are conducted in 00 minutes and are available in both Korean and English.<br />If you are more than 10 minutes late, your reservation will be automatically marked as a no-show, and the tour will be canceled.</p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> For a seamless experience, the tour is limited to a maximum of 15 participants.<br />Please provide the exact number of attendees to ensure proper preparation of docent equipment.</p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> Final approval results and adjusted schedules will be emailed between late December and early January.<br />* Upon submission, your reservation will remain in “Pending Approval” status.<br />* If any changes occur after final confirmation, please contact the following coordinators:<br />&nbsp; &nbsp; Minyoung Park: <a href="mailto:my1011.park@samsung.com" className="ft_blue ssone">my1011.park@samsung.com</a><br />&nbsp; &nbsp; Nayeon Kim: <a href="mailto:vitany.kim@samsung.com" className="ft_blue ssone">vitany.kim@samsung.com</a></p>
              </div>
            </div>
            <div className="information_announce">
              <div className="information_inner">
                <h4>Check-in point</h4>
              </div>
              <div className="information_inner">
                <p className='mb15'>The tour begins in front of the VIP desk, located at the corner to the right of the main entrance of the Samsung booth.</p>
                <p className='mb15 sp_p'><span className='sp_sp'>•</span>Please adhere to the check-in time to ensure a smooth experience for the next VIP tour.</p>
                <p className='mb15 sp_p mb40'><span className='sp_sp'>•</span>After checking in at the VIP, please follow the instructions to join the tour.</p>
                <img className="tourMap" src={tourMap}/>
              </div>
            </div>
            <div className="information_announce">
              <div className="information_inner">
                <h4>Tour route</h4>
              </div>
              <div className="information_inner">
                <p className='mb10'>Sustainability  &nbsp;→&nbsp; SmartThings &nbsp;→&nbsp; VD  &nbsp;→&nbsp; DA &nbsp;→&nbsp;  MX</p>
              </div>
            </div>

          </section>
        ) : (
          <section className="information_en">
            <div className="information_announce">
              <div className="information_inner">
                <h4>Notice</h4>
              </div>
              <div className="information_inner">
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> Samsung CES VIP tour 예약 페이지 오신 것을 환영합니다.</p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> VIP tour는 유연한 귀빈 영접을 위해 삼성 임원이 호스팅하시는 투어로<br/>부사장/지역총괄급 이상 임원 직접 참여를 한정으로 신청받고 있습니다.</p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> 투어는 00분씩 국/영문 두 가지 언어로 지원되며 예약시간으로부터 10분이 지나면 자동 노쇼로 간주되어 투어가 취소됩니다.</p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> 원활한 투어를 위해 인원은 최대 15인으로 제한하고 있습니다.<br/>도슨트 장비 준비를 위해 참석자 인원을 정확히 표기 부탁드립니다. </p>
                <p className='mb15 sp_p'><span className='sp_sp'>-</span> 12월말-1월초 최종 승인 결과 및 조정된 스케쥴 안내 메일 발송될 예정이오니 참고 부탁드립니다.<br/>* 신청 즉시, 예약 결과 대기 상태<br/>* 최종 확정 이후 변동 사항 시, 반드시 담당자 측시 연락 요망<br/>&nbsp; &nbsp; Minyoung Park : <a href="mailto:my1011.park@samsung.com" className="ft_blue ssone">my1011.park@samsung.com</a><br/>&nbsp; &nbsp; Nayeon Kim : <a href="mailto:vitany.kim@samsung.com" className="ft_blue ssone">vitany.kim@samsung.com</a></p>
              </div>
            </div>
            <div className="information_announce">
              <div className="information_inner">
                <h4>Check-in point</h4>
              </div>
              <div className="information_inner">
                <p className='mb15'>투어는 VIP 데스크 앞에서 시작되며, 데스크는 삼성관 메인 입구를 바라보고 섰을 때, 우측 편 코너에 위치하고 있습니다.</p>
                <p className='mb15 sp_p'><span className='sp_sp'>•</span>다음 VIP투어를 위해 체크인 시간을 준수해주십시오.</p>
                <p className='mb15 sp_p mb40'><span className='sp_sp'>•</span>LVCC Central Hall 삼성관 메인 입구 VIP desk에서 VIP tour check-in 후 안내에 따라 투어에 참가해주시기 바랍니다.</p>
                <img className="tourMap" src={tourMap}/>
              </div>
            </div>

            <div className="information_announce">
              <div className="information_inner">
                <h4>Tour route</h4>
              </div>
              <div className="information_inner">
                <p className='mb10'>Sustainability  &nbsp;→&nbsp; SmartThings &nbsp;→&nbsp; VD  &nbsp;→&nbsp; DA &nbsp;→&nbsp; MX</p>
              </div>
            </div>

          </section>
        )
      }
    </>
  );
}
